import { createEntityAdapter, EntityState, Update } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import * as baseState from 'src/app/core/models/base-state';
import { locationSort } from '../../data/location-sort';
import { ApptLocation } from '../../models/location';
import * as locationsActions from '../actions/locations.actions';

export interface LocationsState extends EntityState<ApptLocation> {
  baseState: baseState.BaseState;
}

export const locationsAdapter = createEntityAdapter<ApptLocation>({
  selectId: location => location.locationId
});

export const initialState = locationsAdapter.getInitialState({
  baseState: new baseState.BaseState(locationSort[0])
});

export const locationsFeatureKey = 'locations';

export const locationsReducer = createReducer(
  initialState,

  on(locationsActions.getLocations, state => {
    return {
      ...state,
      baseState: baseState.setGet(state.baseState, false)
    };
  }),

  on(locationsActions.getLocationsSuccess, (state, { locations }) => locationsAdapter.addMany(locations, state)),

  on(locationsActions.getLocationsSuccess, (state, { pageNumber, isAtEndOfData, totalRecordCount }) => {
    return {
      ...state,
      baseState: baseState.setGetSuccess(state.baseState, pageNumber, isAtEndOfData, totalRecordCount)
    };
  }),

  on(locationsActions.getLocationsFailure, (state, { error }) => {
    return {
      ...state,
      baseState: baseState.setError(state.baseState, error)
    };
  }),

  on(
    locationsActions.getLocations,
    locationsActions.setPageSize,
    locationsActions.setPageNumber,
    locationsActions.getLocationsSearch,
    locationsActions.setSortBy,
    locationsActions.setSortDirection,
    locationsActions.setIsActive,
    state => locationsAdapter.removeAll(state)
  ),

  on(locationsActions.setPageNumber, (state, { pageNumber }) => {
    return {
      ...state,
      baseState: baseState.setPageNumber(state.baseState, pageNumber)
    };
  }),

  on(locationsActions.setPageSize, (state, { pageSize }) => {
    return {
      ...state,
      baseState: baseState.setPageSize(state.baseState, pageSize)
    };
  }),

  on(locationsActions.getLocationsSearch, (state, { searchTerm }) => {
    return {
      ...state,
      baseState: baseState.setSearch(state.baseState, searchTerm)
    };
  }),

  on(locationsActions.setSortDirection, (state, { sortDirection }) => {
    return {
      ...state,
      baseState: baseState.setSortDirection(state.baseState, sortDirection)
    };
  }),

  on(locationsActions.setSortBy, (state, { sortBy }) => {
    return {
      ...state,
      baseState: baseState.setSortBy(state.baseState, sortBy)
    };
  }),

  on(locationsActions.setIsActive, (state, { isActive }) => {
    return {
      ...state,
      baseState: baseState.setIsActive(state.baseState, isActive)
    };
  }),

  on(locationsActions.resetFilters, state => {
    return {
      ...state,
      baseState: baseState.resetFilters(state.baseState)
    };
  }),

  on(locationsActions.addLocationSuccess, locationsActions.updateLocation, state => {
    return {
      ...state,
      isSaving: false,
      baseState: baseState.setError(state.baseState, null)
    };
  }),

  on(locationsActions.addLocationFailure, locationsActions.updateLocationFailure, (state, { error }) => {
    return {
      ...state,
      baseState: baseState.setError(state.baseState, error)
    };
  }),

  on(locationsActions.getLocationByIdSuccess, (state, { location }) => {
    const i = state.ids.findIndex((fi: any) => fi == location.locationId);
    if (i < 0) return locationsAdapter.addOne(location, state);

    const updatedLocation: Update<ApptLocation> = {
      id: location.locationId,
      changes: {
        ...location
      }
    };

    return locationsAdapter.updateOne(updatedLocation, state);
  }),

  on(
    locationsActions.getLocationAppointmentTypesByLocationIdSuccess,
    (state, { locationId, locationAppointmentTypes }) => {
      const updatedLocation: Update<ApptLocation> = {
        id: locationId,
        changes: {
          locationAppointmentTypes: {
            appointmentTypeIds:
              locationAppointmentTypes === null ? null : locationAppointmentTypes.map(m => m.appointmentTypeId),
            isDirty: false
          }
        }
      };

      return locationsAdapter.updateOne(updatedLocation, state);
    }
  ),

  on(
    locationsActions.getLocationByIdSuccess,
    locationsActions.getLocationAppointmentTypesByLocationIdSuccess,
    state => {
      return {
        ...state,
        baseState: baseState.setError(state.baseState, null)
      };
    }
  ),

  on(
    locationsActions.getLocationByIdFailure,
    locationsActions.getLocationAppointmentTypesByLocationIdFailure,
    (state, { error }) => {
      return {
        ...state,
        baseState: baseState.setError(state.baseState, error)
      };
    }
  )
);
