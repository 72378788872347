import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatNativeDateModule, MatOption, provideNativeDateAdapter } from '@angular/material/core';
import { MatDatepicker, MatDatepickerInput, MatDatepickerToggle } from '@angular/material/datepicker';
import { MatError, MatFormField, MatLabel } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput, MatInputModule } from '@angular/material/input';
import { MatSelect, MatSelectTrigger } from '@angular/material/select';
import { Color } from 'src/app/core/models/colors';
import { locationColors } from 'src/app/locations/data/location-colors';
import { ApptLocation } from 'src/app/locations/models/location';
import { ProgressSpinnerOverlayComponent } from 'src/app/shared/component/progress-spinner-overlay/progress-spinner-overlay.component';
import { formatDateWithoutTime } from 'src/app/shared/functions/date.functions';
import { dateRangeValidator } from 'src/app/shared/functions/validators/date-range.validator';
import { Resource } from '../../models/resource';
import { ResourceType } from '../../models/resource-type';

@Component({
  selector: 'app-upsert-resource-form',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ProgressSpinnerOverlayComponent,
    MatError,
    MatLabel,
    MatFormField,
    MatDatepicker,
    MatDatepickerInput,
    MatDatepickerToggle,
    MatOption,
    MatSelectTrigger,
    MatNativeDateModule,
    FormsModule,
    MatInputModule,
    MatInput,
    MatButton,
    MatSelect,
    MatIcon
  ],
  providers: [provideNativeDateAdapter()],
  templateUrl: './upsert-resource-form.component.html',
  styleUrl: './upsert-resource-form.component.scss'
})
export class UpsertResourceFormComponent {
  maxLengthName = 50;

  form = new FormGroup({
    nameControl: new FormControl<string | null>(null, [Validators.required, Validators.maxLength(this.maxLengthName)]),
    colorControl: new FormControl<Color | null>(null, Validators.required),
    typeControl: new FormControl<number | null>(null, Validators.required),
    locationControl: new FormControl<number | null>(null, Validators.required),
    activeDatesControl: new FormGroup(
      {
        endDate: new FormControl<Date | null>(null),
        startDate: new FormControl<Date | null>(null)
      },
      { validators: dateRangeValidator }
    )
  });

  today = new Date();
  colors = locationColors;
  deactivatedLocation = false;
  resource: Resource;
  locationsDropdown: ApptLocation[] = [];

  @Input() set resourceToUpdate(value: Resource | null) {
    if (value !== null && value !== undefined) {
      const chosenColor = this.colors.find(el => el.hexCode === value.hexColorCode);
      this.form.controls['locationControl'].disable();
      this.form.controls['nameControl'].patchValue(value.name);
      this.form.controls['colorControl'].patchValue(chosenColor);
      this.form.controls['typeControl'].patchValue(value.resourceTypeLookupId);
      this.form.controls['locationControl'].patchValue(value.locationId);
      if (value.deactivateOn !== null && value.deactivateOn !== undefined) {
        const strDate = value.deactivateOn + ' 12:00:00Z';
        this.form.controls['activeDatesControl'].get('endDate')?.patchValue(new Date(strDate));
      }
      if (value.activateOn !== null && value.activateOn !== undefined) {
        const strDate = value.activateOn + ' 12:00:00Z';
        this.form.controls['activeDatesControl'].get('startDate')?.patchValue(new Date(strDate));
        if (new Date(strDate) < this.today) {
          this.form.controls['activeDatesControl'].get('startDate')?.disable();
        }
      }
      this.resource = value;
    }
    this.locations.map(location => {
      if (location.isActive) {
        this.locationsDropdown.push(location);
      } else if (value !== null && location?.locationId === value?.locationId) {
        const tempLoc: ApptLocation = {
          ...location,
          name: location.name + ' (Inactivated)'
        };
        this.locationsDropdown.push(tempLoc);
        this.form.controls['locationControl'].enable();
      }
    });
  }

  @Input() saveError: string[] | null = null;
  @Input() resourceTypes: ResourceType[] | null;
  @Input() locations: ApptLocation[] | null;
  @Input() modifiedByUserId: number;
  @Input() isLoading: boolean | null;
  @Output() closeAddResourceEvent = new EventEmitter();
  @Output() saveResourceEvent = new EventEmitter<Resource>();

  locationValidator() {
    if (this.form !== undefined && this.form !== null) {
      const selectedLocationId = this.form.controls['locationControl'].value;
      const location = this.locations.find(location => {
        return location.locationId === selectedLocationId;
      });
      if (location !== undefined) {
        if (!location.isActive) {
          this.form.controls['locationControl'].setErrors({ invalidLocation: true });
        } else {
          this.form.controls['locationControl'].setErrors(null);
        }
      }
    }
  }

  save() {
    this.locationValidator();

    if (this.form.valid) {
      const resource: Resource = {
        name: this.form.controls['nameControl'].value,
        hexColorCode: this.form.controls['colorControl'].value.hexCode,
        resourceTypeLookupId: this.form.controls['typeControl'].value,
        locationId: this.form.controls['locationControl'].value,
        deactivateOn: formatDateWithoutTime(this.form.controls['activeDatesControl'].get('endDate')?.value),
        activateOn: formatDateWithoutTime(this.form.controls['activeDatesControl'].get('startDate')?.value),
        resourceId: this.resource?.resourceId,
        version: this.resource?.version,
        modifiedByUserId: this.modifiedByUserId,
        resourceAvailabilities: null
      };

      this.saveResourceEvent.emit(resource);
    }
  }
}
