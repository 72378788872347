import { createFeatureSelector, createSelector } from '@ngrx/store';
import { selectParams } from 'src/app/core/router/store/router.selectors';
import * as fromResources from '../reducer/resources.reducer';

export const resourcesFeatureSelector = createFeatureSelector<fromResources.ResourcesState>(
  fromResources.resourcesFeatureKey
);

const { selectEntities, selectAll } = fromResources.resourcesAdapter.getSelectors();

export const selectResourcesEntities = createSelector(resourcesFeatureSelector, selectEntities);

export const selectResources = createSelector(resourcesFeatureSelector, selectAll);

export const selectError = createSelector(resourcesFeatureSelector, state => state.baseState.error);

export const selectIsLoading = createSelector(resourcesFeatureSelector, state => state.baseState.isLoading);

export const selectPageNumber = createSelector(resourcesFeatureSelector, state => state.baseState.pageNumber);

export const selectIsAtEndOfData = createSelector(resourcesFeatureSelector, state => state.baseState.isAtEndOfData);

export const selectSortDirection = createSelector(resourcesFeatureSelector, state => state.baseState.sortDirection);

export const selectSortBy = createSelector(resourcesFeatureSelector, state => state.baseState.sortBy);

export const selectPageSize = createSelector(resourcesFeatureSelector, state => state.baseState.pageSize);

export const selectTotalRecordCount = createSelector(
  resourcesFeatureSelector,
  state => state.baseState.totalRecordCount
);

export const selectSearchTerm = createSelector(resourcesFeatureSelector, state => state.baseState.searchTerm);

export const selectIsActive = createSelector(resourcesFeatureSelector, state => state.baseState.isActive);

export const selectResourceTypes = createSelector(resourcesFeatureSelector, state => state.resourceTypes);

export const selectResource = createSelector(
  selectResourcesEntities,
  selectParams,
  (resources, { resourceId }) => resources[resourceId]
);
