<ng-container
  *ngrxLet="{
    locations: locations$,
    modifiedByUserId: modifiedByUserId$,
    resourceTypes: resourceTypes$,
    error: saveError$,
    selectedResource: selectedResource$,
    isLoading: isLoading$
  } as vm">
  <app-progress-spinner
    *ngIf="
      vm.locations === [] || vm.resourceTypes === null || vm.modifiedByUserId === null;
      else updateResourceForm
    "></app-progress-spinner>

  <ng-template #updateResourceForm>
    <app-upsert-resource-form
      [saveError]="vm.error"
      [resourceTypes]="vm.resourceTypes"
      [locations]="vm.locations"
      [modifiedByUserId]="vm.modifiedByUserId"
      [resourceToUpdate]="vm.selectedResource"
      [isLoading]="vm.isLoading"
      (closeAddResourceEvent)="cancel()"
      (saveResourceEvent)="updateResource($event)"></app-upsert-resource-form>
  </ng-template>
</ng-container>
