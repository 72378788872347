import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ApptLocation } from 'src/app/locations/models/location';
import { resetFilters, setIsActive, setPageSize } from 'src/app/locations/store/actions/locations.actions';
import { selectLocations } from 'src/app/locations/store/selectors/locations.selectors';
import { ProgressSpinnerComponent } from 'src/app/shared/component/progress-spinner/progress-spinner.component';
import { selectUserId } from 'src/app/user/store/selectors/user.selectors';
import { Resource } from '../../models/resource';
import { ResourceType } from '../../models/resource-type';
import {
  getResourceById,
  getResourceTypes,
  navigateToResourcesGrid,
  updateResource
} from '../../store/actions/resources.actions';
import {
  selectError,
  selectIsLoading,
  selectResource,
  selectResourceTypes
} from '../../store/selectors/resources.selectors';
import { UpsertResourceFormComponent } from '../../ui/upsert-resource-form/upsert-resource-form.component';

@Component({
  selector: 'app-update-resource',
  standalone: true,
  imports: [UpsertResourceFormComponent, CommonModule, ProgressSpinnerComponent, LetDirective],
  templateUrl: './update-resource.component.html',
  styleUrl: './update-resource.component.scss'
})
export class UpdateResourceComponent implements OnInit, OnDestroy {
  saveError$: Observable<string[] | null>;
  resourceTypes$: Observable<ResourceType[] | null>;
  locations$: Observable<ApptLocation[] | null>;
  selectedResource$: Observable<Resource | null>;
  modifiedByUserId$: Observable<number | null>;
  isLoading$: Observable<boolean | null>;

  constructor(private store: Store) {
    this.saveError$ = this.store.select(selectError);
    this.resourceTypes$ = this.store.select(selectResourceTypes);
    this.locations$ = this.store.select(selectLocations);
    this.modifiedByUserId$ = this.store.select(selectUserId);
    this.selectedResource$ = this.store.select(selectResource);
    this.isLoading$ = this.store.select(selectIsLoading);
  }

  ngOnInit(): void {
    this.store.dispatch(setPageSize({ pageSize: 100 }));
    this.store.dispatch(setIsActive({ isActive: null }));
    this.store.dispatch(getResourceTypes());
    this.store.dispatch(getResourceById());
  }

  updateResource(resourceToUpdate: Resource) {
    this.store.dispatch(updateResource({ resourceToUpdate }));
  }

  cancel() {
    this.store.dispatch(navigateToResourcesGrid());
  }

  ngOnDestroy(): void {
    this.store.dispatch(resetFilters()); // reset location filters
  }
}
