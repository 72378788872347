import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { NGXLogger } from 'ngx-logger';
import { catchError, map, mergeMap, of } from 'rxjs';
import { UsStatesService } from '../../services/us-states/us-states.service';
import * as actions from '../actions/shared.actions';

@Injectable()
export class SharedEffects {
  constructor(
    private actions$: Actions,
    private usStatesService: UsStatesService,
    private logger: NGXLogger
  ) {}

  getUsStates$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.getUsStates),
      mergeMap(() => {
        return this.usStatesService.getUsStates().pipe(
          map(usStates => {
            return actions.getUsStatesSuccess({ usStates });
          }),
          catchError(error => {
            this.logger.error(error);
            return of(actions.getUsStatesFailure({ error }));
          })
        );
      })
    );
  });
}
