import { DataSort } from 'src/app/core/models/data-sort';

export const locationSort: DataSort[] = [
  {
    id: 1,
    value: 'name',
    display: 'Location Name',
    isDefault: true
  },
  {
    id: 2,
    value: 'address1',
    display: 'Address Line 1',
    isDefault: false
  }
];
