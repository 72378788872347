import { createAction, props } from '@ngrx/store';
import { DataSort } from 'src/app/core/models/data-sort';
import { AppointmentType } from '../../models/appointment-type';
import { AppointmentTypeCategory } from '../../models/appointment-type-category';

export const getAppointmentTypes = createAction('[Appointment Types] Get Appointment Types');

export const getAppointmentTypesSuccess = createAction(
  '[Appointment Types] Get Appointment Types Success',
  props<{
    appointmentTypes: AppointmentType[];
    pageNumber: number;
    isAtEndOfData: boolean;
    totalRecordCount: number;
  }>()
);

export const getAppointmentTypesFailure = createAction(
  '[Appointment Types] Get Appointment Types Failure',
  props<{ error: any }>()
);

export const getAppointmentTypeSearch = createAction(
  '[Appointment Types] Get Appointment Types Search',
  props<{ searchTerm: any }>()
);

export const setPageNumber = createAction('[Appointment Types] Set Page Number', props<{ pageNumber: number }>());

export const setPageSize = createAction('[Appointment Types] Set Page Size', props<{ pageSize: number }>());

export const setSortDirection = createAction(
  '[Appointment Types] Set Sort Direction',
  props<{ sortDirection: number }>()
);

export const setSortBy = createAction('[Appointment Types] Set Sort By', props<{ sortBy: DataSort }>());

export const setIsActive = createAction('[Appointment Types] Set Is Active', props<{ isActive: boolean }>());

export const getAppointmentTypeCategories = createAction('[Appointment Types] Get Appointment Type Categories');

export const getAppointmentTypeCategoriesSuccess = createAction(
  '[Appointment Types] Get Appointment Type Categories Success',
  props<{ appointmentTypeCategories: AppointmentTypeCategory[] }>()
);

export const getAppointmentTypeCategoriesFailure = createAction(
  '[Appointment Types] Get Appointment Type Categories Failure',
  props<{ error: any }>()
);

export const addAppointmentType = createAction(
  '[Appointment Types] Add Appointment Type',
  props<{ newAppointmentType: AppointmentType }>()
);

export const addAppointmentTypeSuccess = createAction('[Appointment Types] Add Appointment Types Success');

export const addAppointmentTypeFailure = createAction(
  '[Appointment Types] Add Appointment Type Failure',
  props<{ error: any }>()
);

export const getAppointmentTypeById = createAction('[Appointment Types] Get Appointment Type By ID');

export const getAppointmentTypeByIdSuccess = createAction(
  '[Appointment Types] Get Appointment Type By ID Success',
  props<{ appointmentType: AppointmentType }>()
);

export const getAppointmentTypeByIdFailure = createAction(
  '[Appointment Types] Get Appointment Type By ID Failure',
  props<{ error: any }>()
);

export const updateAppointmentType = createAction(
  '[Appointment Types] Update Appointment Type',
  props<{ appointmentType: AppointmentType }>()
);

export const updateAppointmentTypeSuccess = createAction('[Appointment Types] Update Appointment Types Success');

export const updateAppointmentTypeFailure = createAction(
  '[Appointment Types] Update Appointment Type Failure',
  props<{ error: any }>()
);

export const getAppointmentTypeQuickPicks = createAction('[Appointment Types] Get Appointment Type Quick Picks');

export const resetFilters = createAction('[Appointment Types] Reset Filters');

export const navigateToAddAppointmentType = createAction('[Appointment Types] Navigate To Add Appointment Type');
