import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { MatPaginator, MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { MatSort, MatSortModule, Sort, SortDirection } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { DataSort } from 'src/app/core/models/data-sort';
import { ProgressSpinnerComponent } from 'src/app/shared/component/progress-spinner/progress-spinner.component';
import * as MedOneSortDirection from '../../../core/enums/sort-direction';
import { Resource } from '../../models/resource';

@Component({
  selector: 'app-resources-grid',
  standalone: true,
  imports: [ProgressSpinnerComponent, CommonModule, MatTableModule, MatSortModule, MatPaginatorModule],
  templateUrl: './resources-grid.component.html',
  styleUrl: './resources-grid.component.scss'
})
export class ResourcesGridComponent implements AfterViewInit, OnChanges {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort: MatSort = new MatSort();

  paginatorLength = 0;
  currentPage = 0;
  pagedData: Resource[] | null = null;
  currentPageSize = 0;
  matSortDirection: SortDirection = 'asc';
  matSortActive: string = 'name';
  pageSizeOptions = [5, 10, 25, 50, 100];

  dataSource: MatTableDataSource<Resource>;

  @Input() set data(value: Resource[] | null) {
    this.pagedData = value;
    if (this.pagedData !== null && this.pagedData.length > 0) {
      this.dataSource = new MatTableDataSource<Resource>(this.pagedData);
    }
  }

  @Input() set page(value: number | null) {
    this.currentPage = value ?? 0;
  }

  @Input() set pageSize(value: number | null) {
    this.currentPageSize = value ?? 0;
  }

  @Input() set totalRecordCount(value: number | null) {
    this.paginatorLength = value ?? 0;
  }

  @Input() set sortBy(value: DataSort | null) {
    if (value !== null && value !== undefined) {
      this.sort.active = this.matSortActive = value.value;
    }
  }

  @Input() set sortDirection(value: MedOneSortDirection.SortDirection | null) {
    if (value !== null) {
      this.sort.direction = this.matSortDirection =
        value === MedOneSortDirection.SortDirection.Ascending ? 'asc' : 'desc';
    }
  }

  @Input() error: any | null = null;
  @Input() isLoading: boolean | null = false;
  @Output() pageEvent = new EventEmitter<number>();
  @Output() pageSizeEvent = new EventEmitter<number>();
  @Output() sortEvent = new EventEmitter<Sort>();
  @Output() editResourceEvent = new EventEmitter<number>();

  displayedColumns: string[] = ['name', 'resourceType', 'locationName', 'color', 'edit'];

  constructor() {
    this.dataSource = new MatTableDataSource(undefined);
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  ngOnChanges() {
    this.dataSource = new MatTableDataSource<Resource>(this.pagedData ? this.pagedData : undefined);
    if (this.paginator !== undefined) {
      this.paginator.length = this.paginatorLength;
      this.paginator.pageIndex = this.currentPage - 1;
    }
  }

  onPage(event: PageEvent) {
    if (event.pageSize !== this.currentPageSize) {
      this.pageSizeEvent.emit(event.pageSize);
    } else {
      this.pageEvent.emit(event.pageIndex + 1);
    }
  }

  onSort(event: any) {
    this.sortEvent.emit(event);
    this.currentPage = 1;
  }
}
