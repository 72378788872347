import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { NGXLogger } from 'ngx-logger';
import { catchError, map, mergeMap, of, tap } from 'rxjs';
import { ProvidersService } from '../../service/provider.service';
import * as providersActions from '../actions/providers.actions';
import * as providersSelector from '../selectors/providers.selectors';

@Injectable()
export class ProvidersEffects {
  constructor(
    private actions$: Actions,
    private providersService: ProvidersService,
    private store: Store,
    private logger: NGXLogger,
    private router: Router
  ) {}

  getProviders$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        providersActions.getProviders,
        providersActions.setPageNumber,
        providersActions.setPageSize,
        providersActions.getProvidersSearch,
        providersActions.setSortBy,
        providersActions.setIsActive
      ),
      concatLatestFrom(() => [
        this.store.select(providersSelector.selectPageNumber),
        this.store.select(providersSelector.selectSortDirection),
        this.store.select(providersSelector.selectSortBy),
        this.store.select(providersSelector.selectPageSize),
        this.store.select(providersSelector.selectSearchTerm),
        this.store.select(providersSelector.selectIsActive)
      ]),
      mergeMap(([, pageNumber, sortDirection, sortBy, pageSize, searchTerm, isActive]) => {
        return this.providersService
          .postSearchProviders(pageNumber, sortDirection, sortBy, pageSize, searchTerm, isActive)
          .pipe(
            map(response => {
              let isAtEndOfData = false;
              const totalRecordCount = response.totalRecordCount;
              let currentPage = pageNumber;
              let providers = response.result;

              if (providers == null || providers.length == 0) {
                providers = [];
                currentPage--;
                isAtEndOfData = true;
              }

              return providersActions.getProvidersSuccess({
                providers,
                pageNumber: currentPage <= 0 ? 1 : currentPage,
                isAtEndOfData,
                totalRecordCount
              });
            }),
            catchError(err => {
              this.logger.error(err);
              return of(providersActions.getProvidersFailure({ error: err.error?.errors }));
            })
          );
      })
    );
  });

  navigateToAddProvider$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(providersActions.navigateToAddProvider),
        tap(() => {
          this.router.navigate(['providers/add-provider']);
        })
      ),
    { dispatch: false }
  );

  navigateToProvidersGrid$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(providersActions.navigateToProvidersGrid),
        tap(() => {
          this.router.navigate(['providers']);
        })
      ),
    { dispatch: false }
  );
}
