import { Location } from '@angular/common';
import { Component, OnDestroy } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment.development';
import { setGlobalError } from '../core/store/actions/app.actions';
import { selectGlobalError } from '../core/store/selector/app.selector';

@Component({
  selector: 'app-error-page',
  standalone: true,
  imports: [MatButtonModule],
  templateUrl: './error-page.component.html',
  styleUrl: './error-page.component.scss'
})
export class ErrorPageComponent implements OnDestroy {
  globalErrorMessage = environment.errorMessage;
  globalError$: Observable<any>;

  constructor(
    private titleService: Title,
    private store: Store,
    public location: Location,
    private router: Router
  ) {
    this.globalError$ = this.store.select(selectGlobalError);
    this.titleService.setTitle(`Error | ${environment.title}`);
  }

  reportError() {
    console.log('Report the error');
    this.router.navigate(['']);
  }

  tryAgain() {
    this.location.back();
  }

  ngOnDestroy(): void {
    this.store.dispatch(setGlobalError({ error: null }));
  }
}
