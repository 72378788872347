import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UtilityService } from 'src/app/core/services/utility/utility.service';
import { environment } from 'src/environments/environment';
import { ResourceType } from '../../models/resource-type';

@Injectable({
  providedIn: 'root'
})
export class ResourceTypesService {
  constructor(private utilityService: UtilityService) {}

  endpoint = environment.scheduleApiUrl + '/ResourceTypeLookups';

  getResourceTypes(): Observable<ResourceType[]> {
    return this.utilityService.getRequest<ResourceType[]>(this.endpoint);
  }
}
