import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';

@Component({
  selector: 'app-locations-filter-bar',
  standalone: true,
  imports: [FormsModule, MatFormFieldModule, MatIconModule, MatCheckboxModule, MatInputModule, MatButtonModule],
  templateUrl: './locations-filter-bar.component.html',
  styleUrl: './locations-filter-bar.component.scss'
})
export class LocationsFilterBarComponent {
  @Input() searchTerm: string | null = null;
  @Input() includeInactive!: boolean | null;
  @Output() searchEvent = new EventEmitter<string | null>();
  @Output() isActiveEvent = new EventEmitter<boolean>();
  @Output() addLocationEvent = new EventEmitter();
  timeout: any;

  textChange(input: string) {
    this.searchTerm = input;
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      if (this.searchTerm.length >= 3 || this.searchTerm.length === 0) {
        this.searchEvent.emit(this.searchTerm);
      }
    }, 1000);
  }

  clear() {
    this.searchTerm = '';
    this.searchEvent.emit(null);
  }
}
