import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { SortDirection } from 'src/app/core/enums/sort-direction';
import { DataSort } from 'src/app/core/models/data-sort';
import { resourceSort } from '../../data/resource-sort';
import { Resource } from '../../models/resource';
import {
  getResources,
  getResourcesSearch,
  navigateToAddResource,
  navigateToUpdateResource,
  resetFilters,
  setIsActive,
  setPageNumber,
  setPageSize,
  setSortBy,
  setSortDirection
} from '../../store/actions/resources.actions';
import * as selectors from '../../store/selectors/resources.selectors';
import { ResourcesFilterBarComponent } from '../../ui/resources-filter-bar/resources-filter-bar.component';
import { ResourcesGridComponent } from '../../ui/resources-grid/resources-grid.component';

@Component({
  selector: 'app-resources',
  standalone: true,
  imports: [ResourcesGridComponent, ResourcesFilterBarComponent, CommonModule],
  templateUrl: './resources.component.html',
  styleUrl: './resources.component.scss'
})
export class ResourcesComponent implements OnDestroy, OnInit {
  resources$: Observable<Resource[] | null>;
  isLoading$: Observable<boolean>;
  error$: Observable<any>;
  isAtEndOfData$: Observable<boolean>;
  sortDirection$: Observable<SortDirection>;
  sortBy$: Observable<DataSort>;
  totalRecordCount$: Observable<number>;
  currentPage$: Observable<number>;
  pageSize$: Observable<number>;
  searchTerm$: Observable<string>;
  isActive$: Observable<boolean | null>;
  isEditing: boolean = false;

  constructor(private store: Store) {
    this.resources$ = this.store.select(selectors.selectResources);
    this.isLoading$ = this.store.select(selectors.selectIsLoading);
    this.error$ = this.store.select(selectors.selectError);
    this.isAtEndOfData$ = this.store.select(selectors.selectIsAtEndOfData);
    this.sortDirection$ = this.store.select(selectors.selectSortDirection);
    this.sortBy$ = this.store.select(selectors.selectSortBy);
    this.totalRecordCount$ = this.store.select(selectors.selectTotalRecordCount);
    this.currentPage$ = this.store.select(selectors.selectPageNumber);
    this.pageSize$ = this.store.select(selectors.selectPageSize);
    this.searchTerm$ = this.store.select(selectors.selectSearchTerm);
    this.isActive$ = this.store.select(selectors.selectIsActive);
  }

  ngOnInit(): void {
    this.store.dispatch(getResources());
  }

  onPage(pageNumber: number): void {
    this.store.dispatch(setPageNumber({ pageNumber }));
  }

  onPageSize = (pageSize: number) => this.store.dispatch(setPageSize({ pageSize }));

  onSearch(searchTerm: string | null): void {
    this.store.dispatch(getResourcesSearch({ searchTerm }));
  }

  onActiveFiltersChange(isActive: boolean): void {
    this.store.dispatch(setIsActive({ isActive }));
  }

  onSort(sort: Sort): void {
    this.store.dispatch(
      setSortDirection({
        sortDirection: sort.direction === 'desc' ? SortDirection.Descending : SortDirection.Ascending
      })
    );

    let dataSort: DataSort | undefined = resourceSort.find(f => f.value === sort.active);
    if (dataSort === undefined) {
      dataSort = resourceSort.find(f => f.isDefault);
      this.store.dispatch(setSortBy({ sortBy: dataSort! }));
    } else {
      this.store.dispatch(setSortBy({ sortBy: dataSort }));
    }
  }

  addResource() {
    this.isEditing = true;
    this.store.dispatch(navigateToAddResource());
  }

  updateResource(resourceId: number) {
    this.isEditing = true;
    this.store.dispatch(navigateToUpdateResource({ resourceId }));
  }

  ngOnDestroy(): void {
    if (!this.isEditing) this.store.dispatch(resetFilters());
  }
}
