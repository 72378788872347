import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appNumbersOnly]',
  standalone: true
})
export class NumbersOnlyDirective {
  @Input() allowDecimals: boolean = true;
  @Input() decimalPlacesCap: number | null = null;

  private static keyCodeWhiteList: string[] = [
    'Backspace',
    'Tab',
    'NumpadDecimal',
    'Period',
    'Digit1',
    'Digit2',
    'Digit3',
    'Digit4',
    'Digit5',
    'Digit6',
    'Digit7',
    'Digit8',
    'Digit9',
    'Digit0',
    'Numpad1',
    'Numpad2',
    'Numpad3',
    'Numpad4',
    'Numpad5',
    'Numpad6',
    'Numpad7',
    'Numpad8',
    'Numpad9',
    'Numpad0'
  ];

  constructor(
    private el: ElementRef,
    private renderer: Renderer2
  ) {}

  @HostListener('keypress', ['$event']) onKeyPress(event: KeyboardEvent) {
    return this.validateNumber(event);
  }

  validateNumber(event: KeyboardEvent) {
    if (this.isValidInput(event)) {
      return !this.allowDecimals || this.isValidDecimal(event);
    }

    return false;
  }

  isValidInput(event: KeyboardEvent): boolean {
    const code = event.code ? event.code : null;
    if (!code) return false;
    const isKeyAllowed = NumbersOnlyDirective.keyCodeWhiteList.includes(event.code);
    if (code.includes('Digit')) {
      return isKeyAllowed && !event.shiftKey;
    } else if (code === 'NumpadDecimal' || code === 'Period') {
      return this.allowDecimals;
    }
    return isKeyAllowed;
  }

  isValidDecimal(event: KeyboardEvent): boolean {
    let regexString = '';
    if (this.decimalPlacesCap === null) {
      regexString = `^\\d*\\.?\\d*$`;
    } else {
      regexString = `^\\d*\\.?\\d{0,${this.decimalPlacesCap}}$`;
    }
    const regex: RegExp = new RegExp(regexString, 'g');
    const current: string = this.el.nativeElement.value;
    const position = this.el.nativeElement.selectionStart;
    const next: string = [
      current.slice(0, position),
      event.key == 'Decimal' ? '.' : event.key,
      current.slice(position)
    ].join('');
    return String(next).match(regex) !== null;
  }

  @HostListener('paste', ['$event']) blockPaste(event: KeyboardEvent) {
    this.validateField(event);
  }

  validateField(event: KeyboardEvent) {
    /* 
        Avoid direct DOM updation. better to use Renderer2 
        this.el.nativeElement.value = this.el.nativeElement.value.replace(/[^0-9 ]/g, '').replace(/\s/g, '');
      */
    this.renderer.setProperty(
      this.el.nativeElement,
      'value',
      this.el.nativeElement.value.replace(/[^0-9 ]/g, '').replace(/\s/g, '')
    );
    event.preventDefault();
  }
}
