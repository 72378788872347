<div class="filter-table-page">
  <div class="filter-table-combo">
    <app-providers-filter-bar
      [searchTerm]="searchTerm$ | async"
      [isActive]="isActive$ | async"
      (activeFiltersEvent)="onActiveFiltersChange($event)"
      (searchEvent)="onSearch($event)"
      (addProviderEvent)="addProvider()"></app-providers-filter-bar>

    <app-providers-grid
      [data]="providers$ | async"
      [isLoading]="isLoading$ | async"
      [error]="error$ | async"
      [totalRecordCount]="totalRecordCount$ | async"
      [pageSize]="pageSize$ | async"
      [page]="currentPage$ | async"
      [sortBy]="sortBy$ | async"
      [sortDirection]="sortDirection$ | async"
      [hasFilters]="hasFilters"
      (pageEvent)="onPage($event)"
      (pageSizeEvent)="onPageSize($event)"
      (sortEvent)="onSort($event)"></app-providers-grid>
  </div>
</div>
