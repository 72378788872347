import { createAction, props } from '@ngrx/store';
import { DataSort } from 'src/app/core/models/data-sort';
import { ProviderResource } from '../../models/provider';
export const getProviders = createAction('[Providers] Get Providers');

export const getProvidersSuccess = createAction(
  '[Providers] Get Providers Success',
  props<{
    providers: ProviderResource[];
    pageNumber: number;
    isAtEndOfData: boolean;
    totalRecordCount: number;
  }>()
);

export const getProvidersFailure = createAction('[Providers] Get Providers Failure', props<{ error: string[] }>());

export const getProvidersSearch = createAction('[Providers] Get Providers Search', props<{ searchTerm: any }>());

export const setPageNumber = createAction('[Providers] Set Page Number', props<{ pageNumber: number }>());

export const setPageSize = createAction('[Providers] Set Page Size', props<{ pageSize: number }>());

export const setSortDirection = createAction('[Providers] Set Sort Direction', props<{ sortDirection: number }>());

export const setSortBy = createAction('[Providers] Set Sort By', props<{ sortBy: DataSort }>());

export const setIsActive = createAction('[Providers] Set Is Active', props<{ isActive: boolean }>());

export const resetFilters = createAction('[Providers] Reset Filters');

export const navigateToAddProvider = createAction('[Providers] Navigate to Add Provider');

export const navigateToProvidersGrid = createAction('[Providers] Navigate to Providers Grid');
