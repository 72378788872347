import { NavListItem } from '../models/nav-list-item';

export const navList: NavListItem[] = [
  {
    icon: 'book_online',
    name: 'Appointment Types',
    route: 'appointment-types'
  },
  {
    icon: 'location_on',
    name: 'Locations',
    route: 'locations'
  },
  {
    icon: 'table_chart',
    name: 'Resources',
    route: 'resources'
  },
  {
    icon: 'person_pin',
    name: 'Providers',
    route: 'providers'
  }
];
