<div class="flex flex-col gap-10 justify-center align-center mt-90">
  <h1 style="font-size: 50px">Oops!</h1>
  <h4 class="fs-20">Something went wrong on our end. It's not you - it's us.</h4>
  <div class="mt-30 flex gap-20">
    <button
      id="btnReport"
      mat-stroked-button
      type="button"
      color="primary"
      class="btn primary-button"
      (click)="reportError()">
      Report Error
    </button>
    <button
      id="btnTryAgain"
      mat-raised-button
      type="button"
      color="primary"
      class="btn primary-solid-button"
      (click)="tryAgain()">
      Try Again
    </button>
  </div>
</div>
