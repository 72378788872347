<mat-sidenav-container class="sidenav-container" autosize>
  <mat-sidenav mode="side" opened class="sidenav">
    <div class="mt-30"></div>
    <div *ngFor="let navListItem of navList">
      <mat-list-item
        data-test="navListItem"
        style="height: 60px"
        class="pl-0"
        role="listitem"
        routerLink="{{ navListItem.route }}"
        [attr.data-test]="'nav-' + navListItem.route">
        <div
          class="nav-btn"
          routerLinkActive="router-link-active"
          [matTooltipDisabled]="!isCollapsed"
          [matTooltip]="navListItem.name"
          matTooltipPosition="after">
          <div [ngClass]="{ 'justify-center': isCollapsed }">
            <mat-icon>{{ navListItem.icon }}</mat-icon>
            <p *ngIf="!isCollapsed">{{ navListItem.name }}</p>
          </div>
        </div>
      </mat-list-item>
    </div>
    <div *ngIf="!isCollapsed" class="expanded-btn">
      <button mat-icon-button type="button" (click)="isCollapsed = !isCollapsed" data-test="btnCollaspeNav">
        <mat-icon>keyboard_double_arrow_left</mat-icon>
      </button>
    </div>
    <div *ngIf="isCollapsed" class="collapsed-btn">
      <button mat-icon-button type="button" (click)="isCollapsed = !isCollapsed" data-test="btnExpandNav">
        <mat-icon>keyboard_double_arrow_right</mat-icon>
      </button>
    </div>
  </mat-sidenav>
  <mat-sidenav-content>
    <div class="ml-20 mr-20">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
