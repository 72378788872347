<ng-container>
  <app-progress-spinner
    *ngIf="
      usStates === null ||
        appointmentTypeQuickPicks === null ||
        (location !== undefined &&
          location !== null &&
          (location.locationAppointmentTypes === undefined || location.locationAvailabilities === undefined));
      else editLocationForm
    ">
  </app-progress-spinner>

  <ng-template #editLocationForm>
    <form [formGroup]="form" (submit)="save()" class="ml-30 mt-30">
      @if (error !== null && !isSaving) {
        <div id="saveError" class="ml-20 mr-20 mb-20 mt-15 flex justify-center">
          <p>Error! Please try again later.</p>
        </div>
      }

      <div class="flex gap-40">
        <div formGroupName="locationProfile" class="flex flex-col gap-20 flex-1">
          <mat-form-field id="name" appearance="outline" subscriptSizing="dynamic" floatLabel="always">
            <mat-label>Location Name</mat-label>
            <input matInput id="locationName" formControlName="locationName" data-test="iptName" />
            @if (form.controls['locationProfile'].controls['locationName'].hasError('required')) {
              <mat-error id="nameRequired">Name required</mat-error>
            } @else if (form.controls['locationProfile'].controls['locationName'].hasError('maxlength')) {
              <mat-error id="nameMaxLengthError"
                >Must not exceed {{ fieldValidators.maxLengthName }} characters</mat-error
              >
            } @else if (form.controls['locationProfile'].controls['locationName'].hasError('duplicate')) {
              <mat-error id="nameDuplicateError">Location name already exists</mat-error>
            }
          </mat-form-field>

          <mat-form-field id="color" appearance="outline" subscriptSizing="dynamic" floatLabel="always">
            <mat-label>Color</mat-label>
            <mat-select
              hideSingleSelectionIndicator
              formControlName="color"
              class="color-select"
              data-test="selHexColorCode">
              <mat-select-trigger>
                <div class="flex justify-between">
                  {{ form.controls['locationProfile'].controls['color'].value?.name }}
                  <div
                    class="color-pill mr-20"
                    [style]="
                      'background-color: ' + form.controls['locationProfile'].controls['color'].value?.hexCode
                    "></div>
                </div>
              </mat-select-trigger>
              @for (color of locationSettings.colors; track color) {
                <mat-option [value]="color">
                  <div class="flex justify-start w-100p">
                    <div class="w-20x h-20x mr-8" [style]="'background-color: ' + color.hexCode"></div>
                    {{ color.name }}
                  </div>
                </mat-option>
              }
            </mat-select>
            @if (form.controls['locationProfile'].controls['color'].hasError('required')) {
              <mat-error id="colorRequired">Color required</mat-error>
            }
          </mat-form-field>

          <mat-form-field id="short-name" appearance="outline" subscriptSizing="dynamic" floatLabel="always">
            <mat-label>Location Short Name</mat-label>
            <input matInput id="locationShortName" formControlName="locationShortName" data-test="iptShortName" />
            @if (form.controls['locationProfile'].controls['locationShortName'].hasError('required')) {
              <mat-error id="shortNameRequired">Short Name required</mat-error>
            } @else if (form.controls['locationProfile'].controls['locationShortName'].hasError('maxlength')) {
              <mat-error id="shortNameMaxLengthError"
                >Must not exceed {{ fieldValidators.maxLengthShortName }} characters</mat-error
              >
            } @else if (form.controls['locationProfile'].controls['locationShortName'].hasError('duplicate')) {
              <mat-error id="shortNameDuplicateError">Short name already exists</mat-error>
            }
          </mat-form-field>

          <mat-form-field id="address1" appearance="outline" subscriptSizing="dynamic" floatLabel="always">
            <mat-label>Address Line 1</mat-label>
            <input matInput id="addressLine1Input" formControlName="addressLine1" data-test="iptAddress1" />
            @if (form.controls['locationProfile'].controls['addressLine1'].hasError('required')) {
              <mat-error id="addressLine1Required">Address required</mat-error>
            }
            @if (form.controls['locationProfile'].controls['addressLine1'].hasError('maxlength')) {
              <mat-error id="addressLine1MaxLengthError"
                >Must not exceed {{ fieldValidators.maxLengthAddress }} characters</mat-error
              >
            }
          </mat-form-field>

          <mat-form-field id="address2" appearance="outline" subscriptSizing="dynamic" floatLabel="always">
            <mat-label>Address Line 2</mat-label>
            <input matInput id="addressLine2Input" formControlName="addressLine2" data-test="iptAddress2" />
            @if (form.controls['locationProfile'].controls['addressLine2'].hasError('required')) {
              <mat-error id="addressLine2Required">Address required</mat-error>
            }
            @if (form.controls['locationProfile'].controls['addressLine2'].hasError('maxlength')) {
              <mat-error id="addressLine2MaxLengthError"
                >Must not exceed {{ fieldValidators.maxLengthAddress }} characters</mat-error
              >
            }
          </mat-form-field>

          <mat-form-field id="city" appearance="outline" subscriptSizing="dynamic" floatLabel="always">
            <mat-label>City</mat-label>
            <input matInput id="cityInput" formControlName="city" data-test="iptCity" />
            @if (form.controls['locationProfile'].controls['city'].hasError('required')) {
              <mat-error id="cityRequired">City required</mat-error>
            }
            @if (form.controls['locationProfile'].controls['city'].hasError('maxlength')) {
              <mat-error id="cityMaxLengthError"
                >Must not exceed {{ fieldValidators.maxLengthCity }} characters</mat-error
              >
            }
          </mat-form-field>

          <mat-form-field id="state" appearance="outline" subscriptSizing="dynamic" floatLabel="always">
            <mat-label>State</mat-label>
            <mat-select formControlName="state" data-test="selStateAbbreviation" class="state-select">
              @for (state of usStates; track state) {
                <mat-option [value]="state.abbreviation">
                  <div>
                    {{ state.name }}
                  </div>
                </mat-option>
              }
            </mat-select>
            @if (form.controls['locationProfile'].controls['state'].hasError('required')) {
              <mat-error id="stateRequired">State required</mat-error>
            }
          </mat-form-field>

          <mat-form-field id="zip" appearance="outline" subscriptSizing="dynamic" floatLabel="always">
            <mat-label>Zip</mat-label>
            <input
              matInput
              id="zipInput"
              formControlName="zip"
              data-test="iptPostalCode"
              pattern="^[0-9]{5}(?:-[0-9]{4})?$"
              mask="00000-0000"
              [validation]="false"
              [dropSpecialCharacters]="false" />
            @if (form.controls['locationProfile'].controls['zip'].hasError('required')) {
              <mat-error id="zipRequired">Zip code required</mat-error>
            }
            @if (form.controls['locationProfile'].controls['zip'].hasError('pattern')) {
              <mat-error id="zipInvalidError">Invalid zip code</mat-error>
            }
          </mat-form-field>

          <mat-form-field id="phone" appearance="outline" subscriptSizing="dynamic" floatLabel="always">
            <mat-label>Phone</mat-label>
            <input matInput id="phoneInput" formControlName="phone" mask="(000) 000-0000" data-test="iptPhoneNumber" />
            @if (form.controls['locationProfile'].controls['phone'].hasError('required')) {
              <mat-error id="phoneRequired">Phone required</mat-error>
            }
            @if (form.controls['locationProfile'].controls['phone'].hasError('mask')) {
              <mat-error id="phoneMaskError">Must be 10 digits</mat-error>
            }
          </mat-form-field>

          <mat-form-field id="fax" appearance="outline" subscriptSizing="dynamic" floatLabel="always">
            <mat-label>Fax</mat-label>
            <input matInput id="faxInput" formControlName="fax" mask="(000) 000-0000" data-test="iptFaxNumber" />
            @if (form.controls['locationProfile'].controls['fax'].hasError('required')) {
              <mat-error id="faxRequired">Fax required</mat-error>
            }
            @if (form.controls['locationProfile'].controls['fax'].hasError('mask')) {
              <mat-error id="faxMaskError">Must be 10 digits</mat-error>
            }
          </mat-form-field>

          <mat-form-field id="tax-id" appearance="outline" subscriptSizing="dynamic" floatLabel="always">
            <mat-label>Tax ID</mat-label>
            <input matInput id="taxIdInput" formControlName="taxId" data-test="iptTaxPayerIdentificationNumber" />
            @if (form.controls['locationProfile'].controls['taxId'].hasError('required')) {
              <mat-error id="taxIdRequired">Tax ID required</mat-error>
            }
            @if (form.controls['locationProfile'].controls['taxId'].hasError('maxlength')) {
              <mat-error id="taxIdMaxLengthError"
                >Must not exceed {{ fieldValidators.maxLengthContact }} characters</mat-error
              >
            }
          </mat-form-field>

          <div formGroupName="activeDates" class="flex flex-col gap-20 flex-1">
            <mat-form-field id="date" appearance="outline" subscriptSizing="dynamic" floatLabel="always">
              <mat-label>Active Date</mat-label>
              <input
                matInput
                [matDatepicker]="activatePicker"
                id="activeDate"
                formControlName="startDate"
                data-test="dteActivateOn"
                [min]="locationSettings.today"
                [max]="form.controls['locationProfile'].controls['activeDates'].get('endDate')?.value" />
              <mat-datepicker-toggle matIconSuffix [for]="activatePicker"></mat-datepicker-toggle>
              <mat-datepicker #activatePicker></mat-datepicker>
              @if (form.controls['locationProfile'].controls['activeDates'].get('startDate')?.hasError('invalidDate')) {
                <mat-error id="beforeInactiveError">Must be before inactivate date</mat-error>
              } @else if (form.controls['locationProfile'].controls['activeDates'].get('startDate')?.invalid) {
                <mat-error id="activeDateInvalid">Date cannot be in the past</mat-error>
              }
            </mat-form-field>

            <mat-form-field id="date" appearance="outline" subscriptSizing="dynamic" floatLabel="always">
              <mat-label>Inactive Date</mat-label>
              <input
                matInput
                [matDatepicker]="deactivatePicker"
                id="inactiveDate"
                formControlName="endDate"
                data-test="dteDeactivateOn"
                [min]="
                  form.controls['locationProfile'].controls['activeDates'].get('startDate')?.value
                    ? form.controls['locationProfile'].controls['activeDates'].get('startDate')?.value
                    : locationSettings.today
                " />
              <mat-datepicker-toggle matIconSuffix [for]="deactivatePicker"></mat-datepicker-toggle>
              <mat-datepicker #deactivatePicker></mat-datepicker>
              @if (form.controls['locationProfile'].controls['activeDates'].get('endDate')?.hasError('invalidDate')) {
                <mat-error id="afterActiveError">Must be after activate date</mat-error>
              } @else if (form.controls['locationProfile'].controls['activeDates'].get('endDate')?.invalid) {
                <mat-error id="inactiveDateInvalid">Date cannot be in the past</mat-error>
              }
            </mat-form-field>
          </div>
        </div>

        <div class="flex-2 flex flex-col gap-20">
          <app-multiselect-autocomplete
            [multiSelectLabel]="'Appointment Type Quick Picks'"
            key="options"
            [data]="appointmentTypeQuickPicks"
            [selectedData]="selectedAppointmentTypeQuickPicks"
            (multiSelectResult)="onMultiSelectChange($event)">
          </app-multiselect-autocomplete>

          <ng-container formGroupName="locationProfile">
            <mat-form-field
              class="h-100p w-100p"
              id="tips"
              appearance="outline"
              subscriptSizing="dynamic"
              floatLabel="always">
              <mat-label>Scheduling Tips</mat-label>
              <textarea matInput id="tips" rows="32" formControlName="tips" data-test="iptNotes"></textarea>
            </mat-form-field>
          </ng-container>
        </div>

        <div class="location-availability" formGroupName="availability">
          <mat-label class="fw-600">Availability</mat-label>
          @for (dayOfWeek of locationSettings.daysOfWeek; track dayOfWeek) {
            <div formGroupName="{{ dayOfWeek.description | lowercase }}" class="day-of-the-week-div">
              <div class="day-of-the-week">{{ dayOfWeek.description }}</div>
              <div class="time-range-with-error-div">
                <div class="time-range-div">
                  <div class="time-box-div">
                    <dx-date-box
                      formControlName="startTime"
                      type="time"
                      label="Start Time"
                      labelMode="static"
                      [interval]="locationSettings.interval"
                      [inputAttr]="{ 'aria-label': 'Start Time' }"
                      [useMaskBehavior]="true"
                      class="time-box"
                      [ngClass]="{ 'time-invalid': isStartTimeValid(dayOfWeek.description) }">
                    </dx-date-box>
                    @if (
                      form.controls['availability'].get(dayOfWeek.description | lowercase)?.get('startTime')?.errors?.[
                        'required'
                      ]
                    ) {
                      <div id="{{ dayOfWeek.name }}StartRequired" class="error-label">Required</div>
                    } @else if (
                      form.controls['availability'].get(dayOfWeek.description | lowercase)?.get('startTime')?.errors?.[
                        'invalid'
                      ]
                    ) {
                      <div id="{{ dayOfWeek.name }}StartInvalid" class="error-label">Invalid time range</div>
                    }
                  </div>

                  <div class="time-box-div">
                    <dx-date-box
                      formControlName="endTime"
                      type="time"
                      label="End Time"
                      labelMode="static"
                      [interval]="locationSettings.interval"
                      [useMaskBehavior]="true"
                      [inputAttr]="{ 'aria-label': 'End Time' }"
                      class="time-box"
                      [ngClass]="{ 'time-invalid': isEndTimeValid(dayOfWeek.description) }">
                    </dx-date-box>
                    @if (
                      form.controls['availability'].get(dayOfWeek.description | lowercase)?.get('endTime')?.errors?.[
                        'required'
                      ]
                    ) {
                      <div id="{{ dayOfWeek.name }}EndRequired" class="error-label">Required</div>
                    } @else if (
                      form.controls['availability'].get(dayOfWeek.description | lowercase)?.get('endTime')?.errors?.[
                        'invalid'
                      ]
                    ) {
                      <div id="{{ dayOfWeek.name }}EndInvalid" class="error-label">Invalid time range</div>
                    }
                  </div>
                </div>
              </div>
            </div>
          }
          @if (
            form.controls['availability'].touched &&
            form.controls['availability'].dirty &&
            form.controls['availability'].hasError('required')
          ) {
            <mat-error id="availabilityRequired">Availability required</mat-error>
          }
        </div>
      </div>

      <div class="btn-container flex justify-end align-center mb-20">
        <div>
          <button
            class="btn warn-button"
            id="btnCancel"
            color="warn"
            mat-stroked-button
            type="button"
            data-test="btnCancel"
            (click)="closeLocationEvent.emit()">
            Cancel
          </button>
        </div>
        <div class="ml-10">
          <button
            class="btn primary-solid-button"
            id="btnSubmit"
            color="primary"
            mat-raised-button
            type="submit"
            data-test="btnSave"
            [disabled]="isSaving">
            Save
          </button>
        </div>
      </div>
    </form>

    <app-progress-spinner-overlay
      [displayProgressSpinner]="isSaving"
      data-test="spinnerUpsertLocation"></app-progress-spinner-overlay>
  </ng-template>
</ng-container>
