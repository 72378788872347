<div class="error-container">
  <div class="error-header-container">
    <mat-icon fontIcon="sentiment_dissatisfied" color="warn" class="material-symbols-outlined"></mat-icon>
    <h3>404 Error</h3>
  </div>

  <div class="flex align-center justify-center">
    <p>Page not found</p>
  </div>
</div>
