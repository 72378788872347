import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UtilityService } from 'src/app/core/services/utility/utility.service';
import { environment } from 'src/environments/environment';
import { AppointmentTypeCategory } from '../../models/appointment-type-category';

@Injectable({
  providedIn: 'root'
})
export class AppointmentTypeCategoriesService {
  constructor(private utilityService: UtilityService) {}

  endpoint = environment.scheduleApiUrl + '/AppointmentTypeCategoryLookups';

  getAppointmentTypeCategories(): Observable<AppointmentTypeCategory[]> {
    return this.utilityService.getRequest<AppointmentTypeCategory[]>(this.endpoint);
  }
}
