import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { tap } from 'rxjs';
import { setGlobalError } from '../actions/app.actions';
import { selectGlobalError } from '../selector/app.selector';

@Injectable()
export class AppEffects {
  constructor(
    private actions$: Actions,
    private router: Router,
    private store: Store
  ) {}

  navigateToErrorPage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(setGlobalError),
        concatLatestFrom(() => [this.store.select(selectGlobalError)]),
        tap(([, err]) => {
          if (err !== null) this.router.navigate(['error']);
        })
      ),
    { dispatch: false }
  );
}
