import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const dateRangeValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const startDate = control.get('startDate')?.value;
  const endDate = control.get('endDate')?.value;

  if (startDate === null || endDate === null) {
    return null;
  } else if (startDate < endDate) {
    return null;
  } else {
    control.get('startDate')?.setErrors({ invalidDate: true });
    control.get('endDate')?.setErrors({ invalidDate: true });
    return { invalidRange: true };
  }
};
