import { createAction, props } from '@ngrx/store';
import { DataSort } from 'src/app/core/models/data-sort';
import { Resource } from '../../models/resource';
import { ResourceType } from '../../models/resource-type';

export const getResources = createAction('[Resources] Get Resources');

export const getResourcesSuccess = createAction(
  '[Resources] Get Resources Success',
  props<{
    resources: Resource[];
    pageNumber: number;
    isAtEndOfData: boolean;
    totalRecordCount: number;
  }>()
);

export const getResourcesFailure = createAction('[Resources] Get Resources Failure', props<{ error: any }>());

export const getResourcesSearch = createAction('[Resources] Get Resources Search', props<{ searchTerm: any }>());

export const setPageNumber = createAction('[Resources] Set Page Number', props<{ pageNumber: number }>());

export const setPageSize = createAction('[Resources] Set Page Size', props<{ pageSize: number }>());

export const setSortDirection = createAction('[Resources] Set Sort Direction', props<{ sortDirection: number }>());

export const setSortBy = createAction('[Resources] Set Sort By', props<{ sortBy: DataSort }>());

export const setIsActive = createAction('[Resources] Set Is Active', props<{ isActive: boolean }>());

export const navigateToAddResource = createAction('[Resources] Navigate to Add Resource');

export const navigateToResourcesGrid = createAction('[Resources] Navigate to Resources Grid');

export const resetFilters = createAction('[Resources] Reset Fiilters');

export const addResource = createAction('[Resources] Add Resource', props<{ resource: Resource }>());

export const addResourceSuccess = createAction('[Resources] Add Resource Success');

export const addResourceFailure = createAction('[Resources] Add Resource Failure', props<{ error: any }>());

export const getResourceTypes = createAction('[Resources] Get Resource Types');

export const getResourceTypesSuccess = createAction(
  '[Resources] Get Resource Types Success',
  props<{ resourceTypes: ResourceType[] }>()
);

export const getResourceTypesFailure = createAction('[Resources] Get Resource Types Failure', props<{ error: any }>());

export const navigateToUpdateResource = createAction(
  '[Resources] Navigate to Update Resource',
  props<{ resourceId: number }>()
);

export const getResourceById = createAction('[Resources] Get Resource By ID');

export const getResourceByIdSuccess = createAction(
  '[Resources] Get Resource By ID Success',
  props<{ resource: Resource }>()
);

export const getResourceByIdFailure = createAction('[Resources] Get Resource By ID Failure', props<{ error: any }>());

export const updateResource = createAction('[Resources] Update Resource', props<{ resourceToUpdate: Resource }>());

export const updateResourceSuccess = createAction('[Resources] Update Resource Success');

export const updateResourceFailure = createAction('[Resources] Update Resource Failure', props<{ error: any }>());
