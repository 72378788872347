import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SortDirection } from 'src/app/core/enums/sort-direction';
import { DataResponse } from 'src/app/core/models/data-response';
import { DataSort } from 'src/app/core/models/data-sort';
import { SearchParameters } from 'src/app/core/models/search-parameters';
import { UtilityService } from 'src/app/core/services/utility/utility.service';
import { environment } from 'src/environments/environment';
import { Resource } from '../../models/resource';

@Injectable({
  providedIn: 'root'
})
export class ResourcesService {
  constructor(private utilityService: UtilityService) {}

  resourceEndpoint = environment.scheduleApiUrl + '/Resources';

  postSearchResources(
    pageNumber: number,
    sortDirection: SortDirection,
    sortBy: DataSort,
    pageSize: number | null = null,
    searchTerm: string | null,
    isActive: boolean
  ): Observable<DataResponse<Resource[]>> {
    const url = this.resourceEndpoint + '/Search';
    const searchParams: any = new SearchParameters(pageNumber, sortDirection, sortBy, pageSize, searchTerm, isActive);
    return this.utilityService.postRequest<DataResponse<Resource[]>>(url, searchParams, false, false, true);
  }

  addResource(resource: Resource): Observable<Resource> {
    return this.utilityService.postRequest<Resource>(this.resourceEndpoint, resource);
  }

  updateResource(resource: Resource): Observable<Resource> {
    const url = this.resourceEndpoint + '/' + resource.resourceId;
    return this.utilityService.putRequest<Resource>(url, resource);
  }

  getResourceById(resourceId: number): Observable<Resource> {
    const url = this.resourceEndpoint + '/' + resourceId;
    return this.utilityService.getRequest<Resource>(url);
  }
}
