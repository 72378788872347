<div class="flex gap-12 align-center justify-between mb-20">
  <div class="flex gap-12 align-center flex-2">
    <mat-form-field class="flex-2" appearance="outline" floatLabel="always" subscriptSizing="dynamic">
      <mat-label>Location Search</mat-label>
      <input
        matInput
        type="text"
        [(ngModel)]="searchTerm"
        (ngModelChange)="textChange($event)"
        data-test="iptSearch"
        placeholder="Enter location or address" />
      @if (searchTerm) {
        <button id="clearBtn" matSuffix mat-icon-button aria-label="Clear" (click)="clear()" data-test="btnClearSearch">
          <mat-icon>close</mat-icon>
        </button>
      }
    </mat-form-field>
    <mat-checkbox
      [(ngModel)]="includeInactive"
      (ngModelChange)="isActiveEvent.emit(includeInactive)"
      name="include-inactive"
      data-test="cbxInactive"
      >Include Inactive</mat-checkbox
    >
  </div>

  <div class="flex-1 flex justify-end">
    <button
      id="add-type-btn"
      class="fs-14 btn primary-solid-button"
      mat-raised-button
      color="primary"
      type="button"
      data-test="btnAddLocation"
      (click)="addLocationEvent.emit()">
      Add Location
    </button>
  </div>
</div>
