<div class="filter-table-page">
  <div class="filter-table-combo">
    <app-appointment-types-filter-bar
      [searchTerm]="searchTerm$ | async"
      [isActive]="isActive$ | async"
      (activeFiltersEvent)="onActiveFiltersChange($event)"
      (searchEvent)="onSearch($event)"
      (addAppointmentTypeEvent)="addAppointmentType()"></app-appointment-types-filter-bar>

    <app-appointment-types-grid
      [data]="appointmentTypes$ | async"
      [isLoading]="isLoading$ | async"
      [error]="error$ | async"
      [totalRecordCount]="totalRecordCount$ | async"
      [pageSize]="pageSize$ | async"
      [page]="currentPage$ | async"
      [sortBy]="sortBy$ | async"
      [sortDirection]="sortDirection$ | async"
      (pageEvent)="onPage($event)"
      (pageSizeEvent)="onPageSize($event)"
      (sortEvent)="onSort($event)"
      (updateAppointmentTypeEvent)="updateAppointmentType($event)"></app-appointment-types-grid>
  </div>
</div>
