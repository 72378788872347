import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { navList } from 'src/app/shared/data/nav-list';
import { NavListItem } from 'src/app/shared/models/nav-list-item';

@Component({
  selector: 'app-side-nav',
  standalone: true,
  imports: [MatSidenavModule, MatListModule, MatTooltipModule, CommonModule, MatIconModule, RouterModule],
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent {
  navList: NavListItem[];
  isCollapsed = false;

  constructor() {
    this.navList = navList;
  }
}
