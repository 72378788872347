import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatNativeDateModule, MatOption, provideNativeDateAdapter } from '@angular/material/core';
import { MatDatepicker, MatDatepickerInput, MatDatepickerToggle } from '@angular/material/datepicker';
import { MatError, MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import { Color } from 'src/app/core/models/colors';
import { MultiselectAutocompleteComponent } from 'src/app/shared/component/multiselect-autocomplete/multiselect-autocomplete.component';
import { ProgressSpinnerOverlayComponent } from 'src/app/shared/component/progress-spinner-overlay/progress-spinner-overlay.component';
import { MultiSelectAutoCompleteItem } from 'src/app/shared/models/multiselect-autocomplete-item';
import { UsState } from 'src/app/shared/models/state';
import { locationColors } from '../../data/location-colors';
import { ApptLocation } from '../../models/location';

@Component({
  selector: 'app-upsert-location-form',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatButton,
    MatDatepicker,
    MatDatepickerInput,
    MatDatepickerToggle,
    MatError,
    MatFormField,
    MatInputModule,
    MatLabel,
    NgxMaskDirective,
    MatNativeDateModule,
    MatOption,
    MatSelectModule,
    MultiselectAutocompleteComponent,
    ProgressSpinnerOverlayComponent,
    ReactiveFormsModule
  ],
  providers: [provideNgxMask(), provideNativeDateAdapter()],
  templateUrl: './upsert-location-form.component.html',
  styleUrl: './upsert-location-form.component.scss'
})
export class UpsertLocationFormComponent {
  today = new Date();
  colors = locationColors;
  maxLengthName = 50;
  maxLengthShortName = 20;
  maxLengthAddress = 50;
  maxLengthCity = 30;
  maxLengthZip = 10;
  maxLengthContact = 20;
  isSaving: boolean = false;
  error: string[] | null = null;
  apptTypeMultiSelectItems: MultiSelectAutoCompleteItem[];
  form = new FormGroup({
    locationProfile: new FormGroup({
      locationNameControl: new FormControl<string | null>(null, [
        Validators.required,
        Validators.maxLength(this.maxLengthName)
      ]),
      colorControl: new FormControl<Color | null>(null, Validators.required),
      locationShortNameControl: new FormControl<string | null>(null, [
        Validators.required,
        Validators.maxLength(this.maxLengthShortName)
      ]),
      addressLine1Control: new FormControl<string | null>(null, [
        Validators.required,
        Validators.maxLength(this.maxLengthAddress)
      ]),
      addressLine2Control: new FormControl<string | null>(null, Validators.maxLength(this.maxLengthAddress)),
      stateControl: new FormControl<string | null>(null, Validators.required),
      cityControl: new FormControl<string | null>(null, [
        Validators.required,
        Validators.maxLength(this.maxLengthCity)
      ]),
      zipControl: new FormControl<string | null>(null, [Validators.required, Validators.maxLength(this.maxLengthZip)]),
      phoneControl: new FormControl<string | null>(null, [
        Validators.required,
        Validators.maxLength(this.maxLengthContact)
      ]),
      faxControl: new FormControl<string | null>(null, Validators.maxLength(this.maxLengthContact)),
      taxIdControl: new FormControl<string | null>(null, Validators.maxLength(this.maxLengthContact)),
      inactiveDateControl: new FormControl<Date | null>(null),
      tipsControl: new FormControl<string | null>(null)
    }),
    appointmentTypeQuickPicksControl: new FormControl<MultiSelectAutoCompleteItem[] | null>(null)
  });
  location: ApptLocation | null = null;
  selectedAppointmentTypeQuickPicks: MultiSelectAutoCompleteItem[] | null = null;

  @Input() set saveError(value: any) {
    if (value !== null && value !== undefined) {
      this.isSaving = false;

      if (value.filter((f: string) => f.toLowerCase().includes('location name')).length > 0) {
        this.form.controls['locationProfile'].controls['locationNameControl'].setErrors({ duplicate: true });
      } else if (value.filter((f: string) => f.toLowerCase().includes('location short name')).length > 0) {
        this.form.controls['locationProfile'].controls['locationShortNameControl'].setErrors({ duplicate: true });
      } else {
        this.error = value;
      }
    }
  }
  @Input() usStates: UsState[] | null;
  @Input() appointmentTypeQuickPicks: MultiSelectAutoCompleteItem[] | null = null;
  @Input() modifiedByUserId: number;
  @Input() set selectedLocation(value: ApptLocation | null) {
    if (value !== null && value !== undefined && value.locationAppointmentTypes !== undefined) {
      this.location = value;
      this.form.controls['locationProfile'].controls['locationNameControl'].patchValue(this.location.name);
      const chosenColor = this.colors.find(el => el.hexCode === this.location.hexColorCode);
      this.form.controls['locationProfile'].controls['colorControl'].patchValue(chosenColor);
      this.form.controls['locationProfile'].controls['locationShortNameControl'].patchValue(this.location.shortName);
      this.form.controls['locationProfile'].controls['addressLine1Control'].patchValue(this.location.address1);
      this.form.controls['locationProfile'].controls['addressLine2Control'].patchValue(this.location.address2);
      this.form.controls['locationProfile'].controls['cityControl'].patchValue(this.location.city);
      this.form.controls['locationProfile'].controls['stateControl'].patchValue(this.location.stateAbbreviation);
      this.form.controls['locationProfile'].controls['zipControl'].patchValue(this.location.postalCode);
      this.form.controls['locationProfile'].controls['phoneControl'].patchValue(this.location.phoneNumber);
      this.form.controls['locationProfile'].controls['faxControl'].patchValue(this.location.faxNumber);
      this.form.controls['locationProfile'].controls['taxIdControl'].patchValue(
        this.location.taxpayerIdentificationNumber
      );
      if (this.location.deactivateOn !== null && this.location.deactivateOn !== undefined) {
        const strDate = this.location.deactivateOn + ' 12:00:00Z';
        this.form.controls['locationProfile'].controls['inactiveDateControl'].patchValue(new Date(strDate));
      }
      this.form.controls['locationProfile'].controls['tipsControl'].patchValue(this.location.notes);

      if (
        this.location.locationAppointmentTypes.appointmentTypeIds !== null &&
        this.location.locationAppointmentTypes.appointmentTypeIds.length > 0
      ) {
        this.selectedAppointmentTypeQuickPicks = this.appointmentTypeQuickPicks.filter(ft =>
          this.location.locationAppointmentTypes.appointmentTypeIds.find(fd => ft.id === fd)
        );
      }
    }
  }
  @Output() closeAddLocationEvent = new EventEmitter();
  @Output() addLocationEvent = new EventEmitter<ApptLocation>();
  @Output() updateLocationEvent = new EventEmitter<ApptLocation>();

  constructor() {}

  onMultiSelectChange = (event: any) => {
    this.form.controls['appointmentTypeQuickPicksControl'].patchValue(event.data);
    this.form.controls['appointmentTypeQuickPicksControl'].markAsDirty();
  };

  save() {
    this.isSaving = true;

    if (this.form.valid) {
      const inactiveDate = new Date(this.form.controls['locationProfile'].controls['inactiveDateControl'].value);
      const inactiveDateStr = this.form.controls['locationProfile'].controls['inactiveDateControl'].value
        ? inactiveDate.toJSON().substring(0, 10)
        : null;

      const location: ApptLocation = {
        locationId: this.location === null ? null : this.location.locationId,
        name: this.form.controls['locationProfile'].controls['locationNameControl'].value,
        shortName: this.form.controls['locationProfile'].controls['locationShortNameControl'].value,
        hexColorCode: this.form.controls['locationProfile'].controls['colorControl'].value.hexCode,
        address1: this.form.controls['locationProfile'].controls['addressLine1Control'].value,
        address2: this.form.controls['locationProfile'].controls['addressLine2Control'].value,
        city: this.form.controls['locationProfile'].controls['cityControl'].value,
        stateAbbreviation: this.form.controls['locationProfile'].controls['stateControl'].value,
        postalCode: this.form.controls['locationProfile'].controls['zipControl'].value,
        phoneNumber: this.form.controls['locationProfile'].controls['phoneControl'].value,
        faxNumber: this.form.controls['locationProfile'].controls['faxControl'].value,
        taxpayerIdentificationNumber: this.form.controls['locationProfile'].controls['taxIdControl'].value,
        deactivateOn: inactiveDateStr,
        notes: this.form.controls['locationProfile'].controls['tipsControl'].value,
        modifiedByUserId: this.modifiedByUserId,
        isDirty: this.form.controls['locationProfile'].dirty,
        locationAppointmentTypes: {
          appointmentTypeIds:
            this.form.controls['appointmentTypeQuickPicksControl'].value === null
              ? null
              : this.form.controls['appointmentTypeQuickPicksControl'].value.map(m => m.id),
          isDirty: this.form.controls['appointmentTypeQuickPicksControl'].dirty
        },
        version: this.location === null ? null : this.location.version
      };

      if (this.location !== null) {
        this.updateLocationEvent.emit(location);
      } else {
        this.addLocationEvent.emit(location);
      }
    } else {
      this.isSaving = false;
    }
  }
}
