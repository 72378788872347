import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromProviders from '../reducer/providers.reducer';

export const providersFeatureSelector = createFeatureSelector<fromProviders.ProvidersState>(
  fromProviders.providersFeatureKey
);

const { selectEntities, selectAll } = fromProviders.providersAdapter.getSelectors();

export const selectProvidersEntities = createSelector(providersFeatureSelector, selectEntities);

export const selectProviders = createSelector(providersFeatureSelector, selectAll);

export const selectError = createSelector(providersFeatureSelector, state => state.baseState.error);

export const selectIsLoading = createSelector(providersFeatureSelector, state => state.baseState.isLoading);

export const selectPageNumber = createSelector(providersFeatureSelector, state => state.baseState.pageNumber);

export const selectIsAtEndOfData = createSelector(providersFeatureSelector, state => state.baseState.isAtEndOfData);

export const selectSortDirection = createSelector(providersFeatureSelector, state => state.baseState.sortDirection);

export const selectSortBy = createSelector(providersFeatureSelector, state => state.baseState.sortBy);

export const selectPageSize = createSelector(providersFeatureSelector, state => state.baseState.pageSize);

export const selectTotalRecordCount = createSelector(
  providersFeatureSelector,
  state => state.baseState.totalRecordCount
);

export const selectSearchTerm = createSelector(providersFeatureSelector, state => state.baseState.searchTerm);

export const selectIsActive = createSelector(providersFeatureSelector, state => state.baseState.isActive);
