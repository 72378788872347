import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, ValidationErrors, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatNativeDateModule, provideNativeDateAdapter } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { DxDateBoxModule, DxValidatorModule } from 'devextreme-angular';
import { Color } from 'src/app/core/models/colors';
import { locationColors } from 'src/app/locations/data/location-colors';
import { ApptLocation } from 'src/app/locations/models/location';
import { ProgressSpinnerOverlayComponent } from 'src/app/shared/component/progress-spinner-overlay/progress-spinner-overlay.component';
import { ProgressSpinnerComponent } from 'src/app/shared/component/progress-spinner/progress-spinner.component';
import {
  createAvailabilityFormGroup,
  getAvailabilities,
  isTimeValid
} from 'src/app/shared/functions/availability.functions';
import { formatDateWithoutTime } from 'src/app/shared/functions/date.functions';
import { dateRangeValidator } from 'src/app/shared/functions/validators/date-range.validator';
import { resourceLengthValidators, resourceSettings } from '../../data/resource-form';
import { Resource } from '../../models/resource';
import { ResourceType } from '../../models/resource-type';

@Component({
  selector: 'app-add-resource-form',
  standalone: true,
  imports: [
    CommonModule,
    DxDateBoxModule,
    DxValidatorModule,
    FormsModule,
    MatButtonModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatNativeDateModule,
    MatSelectModule,
    ProgressSpinnerComponent,
    ProgressSpinnerOverlayComponent,
    ReactiveFormsModule
  ],
  providers: [provideNativeDateAdapter()],
  templateUrl: './add-resource-form.component.html',
  styleUrl: './add-resource-form.component.scss'
})
export class AddResourceFormComponent {
  fieldValidators = resourceLengthValidators;
  resourceSettings = resourceSettings;

  form = new FormGroup({
    profile: new FormGroup({
      name: new FormControl<string | null>(null, [
        Validators.required,
        Validators.maxLength(this.fieldValidators.maxLengthName)
      ]),
      color: new FormControl<Color | null>(null, Validators.required),
      type: new FormControl<number | null>(null, Validators.required),
      location: new FormControl<number | null>(null, Validators.required),
      activeDates: new FormGroup(
        {
          endDate: new FormControl<Date | null>(null),
          startDate: new FormControl<Date | null>(null)
        },
        { validators: dateRangeValidator }
      )
    }),
    availability: createAvailabilityFormGroup(true)
  });

  minDateAllowed = new Date();
  colors = locationColors;
  resource: Resource;
  isSaving: boolean = false;

  @Input() locations: ApptLocation[] | null;
  @Input() modifiedByUserId: number;
  @Input() resourceTypes: ResourceType[] | null;
  @Input() saveError: string[] | null = null;
  @Output() closeAddResourceEvent = new EventEmitter();
  @Output() saveResourceEvent = new EventEmitter<Resource>();

  constructor() {
    this.form.controls['availability'].markAsDirty();
  }

  isValid = (errors: ValidationErrors): boolean => isTimeValid(errors);

  save() {
    this.isSaving = true;

    if (this.form.valid) {
      const resource: Resource = {
        name: this.form.controls['profile'].controls['name'].value,
        hexColorCode: this.form.controls['profile'].controls['color'].value.hexCode,
        resourceTypeLookupId: this.form.controls['profile'].controls['type'].value,
        locationId: this.form.controls['profile'].controls['location'].value,
        deactivateOn: formatDateWithoutTime(
          this.form.controls['profile'].controls['activeDates'].get('endDate')?.value
        ),
        activateOn: formatDateWithoutTime(
          this.form.controls['profile'].controls['activeDates'].get('startDate')?.value
        ),
        modifiedByUserId: this.modifiedByUserId,
        resourceAvailabilities: {
          dayOfWeekAvailabilities: getAvailabilities(this.form.controls['availability']),
          isDirty: this.form.controls['availability'].dirty
        }
      };

      this.saveResourceEvent.emit(resource);
    } else {
      this.isSaving = false;
    }
  }
}
