import { createReducer, on } from '@ngrx/store';

import * as appActions from '../actions/app.actions';

export const appFeatureKey = 'app';

export interface AppState {
  globalError: any;
}

export const initialState: AppState = {
  globalError: null
};

export const appReducer = createReducer<AppState>(
  initialState,

  on(appActions.setGlobalError, (state, { error }) => {
    return {
      ...state,
      globalError: error
    };
  })
);
