import { Component } from '@angular/core';
import { HeaderComponent } from '../header/header.component';
import { SideNavComponent } from '../side-nav/side-nav.component';
import { UserMenuComponent } from '../user-menu/user-menu.component';

@Component({
  selector: 'app-layout-with-nav',
  standalone: true,
  imports: [HeaderComponent, SideNavComponent, UserMenuComponent],
  templateUrl: './layout-with-nav.component.html',
  styleUrls: ['./layout-with-nav.component.scss']
})
export class LayoutWithNavComponent {}
