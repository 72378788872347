import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { getAppointmentTypes } from 'src/app/appointment-types/store/actions/appointment-types.actions';
import { selectAppointmentTypeQuickPicks } from 'src/app/appointment-types/store/selector/appointment-types.selector';
import { ProgressSpinnerComponent } from 'src/app/shared/component/progress-spinner/progress-spinner.component';
import { MultiSelectAutoCompleteItem } from 'src/app/shared/models/multiselect-autocomplete-item';
import { UsState } from 'src/app/shared/models/state';
import { getUsStates } from 'src/app/shared/store/actions/shared.actions';
import { selectUsStates } from 'src/app/shared/store/selector/shared.selector';
import { selectUserId } from 'src/app/user/store/selectors/user.selectors';
import { ApptLocation } from '../../models/location';
import {
  getLocationById,
  navigateToLocationsGrid,
  updateLocation,
  updateLocationAppointmentTypes,
  updateLocationAvailabilities
} from '../../store/actions/locations.actions';
import { selectError, selectLocation } from '../../store/selectors/locations.selectors';
import { EditLocationFormComponent } from '../../ui/edit-location-form/edit-location-form.component';

@Component({
  selector: 'app-update-location',
  standalone: true,
  imports: [CommonModule, LetDirective, ProgressSpinnerComponent, EditLocationFormComponent],
  templateUrl: './update-location.component.html',
  styleUrl: './update-location.component.scss'
})
export class UpdateLocationComponent implements OnInit {
  error$: Observable<string[] | null>;
  usStates$: Observable<UsState[] | null>;
  appointmentTypeQuickPicks$: Observable<MultiSelectAutoCompleteItem[]>;
  modifiedByUserId$: Observable<number | null>;
  selectedLocation$: Observable<ApptLocation>;

  constructor(private store: Store) {
    this.error$ = this.store.select(selectError);
    this.usStates$ = this.store.select(selectUsStates);
    this.appointmentTypeQuickPicks$ = this.store.select(selectAppointmentTypeQuickPicks);
    this.modifiedByUserId$ = this.store.select(selectUserId);
    this.selectedLocation$ = this.store.select(selectLocation);
  }

  ngOnInit(): void {
    this.store.dispatch(getUsStates());
    this.store.dispatch(getAppointmentTypes());
    this.store.dispatch(getLocationById());
  }

  updateLocation(location: ApptLocation) {
    this.store.dispatch(updateLocation({ location }));
    this.store.dispatch(
      updateLocationAppointmentTypes({
        locationId: location.locationId,
        locationAppointmentTypes: location.locationAppointmentTypes
      })
    );
    this.store.dispatch(
      updateLocationAvailabilities({
        locationId: location.locationId,
        locationAvailabilities: location.locationAvailabilities
      })
    );
  }

  cancel() {
    this.store.dispatch(navigateToLocationsGrid());
  }
}
