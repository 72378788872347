import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { Color } from 'src/app/core/models/colors';
import { createAvailabilityFormGroup, getAvailabilities } from 'src/app/shared/functions/availability.functions';
import { formatDateWithoutTime } from 'src/app/shared/functions/date.functions';
import { dateRangeValidator } from 'src/app/shared/functions/validators/date-range.validator';
import { MultiSelectAutoCompleteItem } from 'src/app/shared/models/multiselect-autocomplete-item';
import { locationLengthValidators } from '../data/location-form';
import { ApptLocation } from '../models/location';

export function createLocationToSave(
  control: AbstractControl,
  locationId: number | null,
  version: string | null,
  modifiedByUserId: number
): ApptLocation {
  const form = control as FormGroup;

  const location: ApptLocation = {
    locationId,
    name: form.controls['locationProfile'].get('locationName')?.value,
    shortName: form.controls['locationProfile'].get('locationShortName')?.value,
    hexColorCode: form.controls['locationProfile'].get('color')?.value.hexCode,
    address1: form.controls['locationProfile'].get('addressLine1')?.value,
    address2: form.controls['locationProfile'].get('addressLine2')?.value,
    city: form.controls['locationProfile'].get('city')?.value,
    stateAbbreviation: form.controls['locationProfile'].get('state')?.value,
    postalCode: form.controls['locationProfile'].get('zip')?.value,
    phoneNumber: form.controls['locationProfile'].get('phone')?.value,
    faxNumber: form.controls['locationProfile'].get('fax')?.value,
    taxpayerIdentificationNumber: form.controls['locationProfile'].get('taxId')?.value,
    deactivateOn: formatDateWithoutTime(form.controls['locationProfile'].get('activeDates')?.get('endDate')?.value),
    activateOn: formatDateWithoutTime(form.controls['locationProfile'].get('activeDates')?.get('startDate')?.value),
    notes: form.controls['locationProfile'].get('tips')?.value,
    modifiedByUserId,
    version,
    isDirty: form.controls['locationProfile'].dirty,

    locationAppointmentTypes: {
      appointmentTypeIds:
        form.controls['appointmentTypeQuickPicks'].value === null
          ? null
          : form.controls['appointmentTypeQuickPicks'].value.map(m => m.id),
      isDirty: form.controls['appointmentTypeQuickPicks'].dirty
    },

    locationAvailabilities: {
      dayOfWeekAvailabilities: getAvailabilities(form.controls['availability']),
      isDirty: form.controls['availability'].dirty
    }
  };

  return location;
}

export function createLocationForm(isAdd: boolean) {
  return new FormGroup({
    locationProfile: new FormGroup({
      locationName: new FormControl<string | null>(null, [
        Validators.required,
        Validators.maxLength(locationLengthValidators.maxLengthName)
      ]),
      color: new FormControl<Color | null>(null, Validators.required),
      locationShortName: new FormControl<string | null>(null, [
        Validators.required,
        Validators.maxLength(locationLengthValidators.maxLengthShortName)
      ]),
      addressLine1: new FormControl<string | null>(null, [
        Validators.required,
        Validators.maxLength(locationLengthValidators.maxLengthAddress)
      ]),
      addressLine2: new FormControl<string | null>(
        null,
        Validators.maxLength(locationLengthValidators.maxLengthAddress)
      ),
      state: new FormControl<string | null>(null, Validators.required),
      city: new FormControl<string | null>(null, [
        Validators.required,
        Validators.maxLength(locationLengthValidators.maxLengthCity)
      ]),
      zip: new FormControl<string | null>(null, [
        Validators.required,
        Validators.maxLength(locationLengthValidators.maxLengthZip)
      ]),
      phone: new FormControl<string | null>(null, [
        Validators.required,
        Validators.maxLength(locationLengthValidators.maxLengthContact)
      ]),
      fax: new FormControl<string | null>(null, Validators.maxLength(locationLengthValidators.maxLengthContact)),
      taxId: new FormControl<string | null>(null, Validators.maxLength(locationLengthValidators.maxLengthContact)),
      activeDates: new FormGroup(
        {
          endDate: new FormControl<Date | null>(null),
          startDate: new FormControl<Date | null>(null)
        },
        { validators: dateRangeValidator }
      ),
      tips: new FormControl<string | null>(null)
    }),
    appointmentTypeQuickPicks: new FormControl<MultiSelectAutoCompleteItem[] | null>(null),
    availability: createAvailabilityFormGroup(isAdd)
  });
}
