<div class="filter-table-page">
  <div class="filter-table-combo">
    <app-resources-filter-bar
      [searchTerm]="searchTerm$ | async"
      [isActive]="isActive$ | async"
      (activeFiltersEvent)="onActiveFiltersChange($event)"
      (searchEvent)="onSearch($event)"
      (addResourceEvent)="addResource()"></app-resources-filter-bar>

    <app-resources-grid
      [data]="resources$ | async"
      [isLoading]="isLoading$ | async"
      [error]="error$ | async"
      [totalRecordCount]="totalRecordCount$ | async"
      [pageSize]="pageSize$ | async"
      [page]="currentPage$ | async"
      [sortBy]="sortBy$ | async"
      [sortDirection]="sortDirection$ | async"
      (pageEvent)="onPage($event)"
      (pageSizeEvent)="onPageSize($event)"
      (sortEvent)="onSort($event)"
      (editResourceEvent)="updateResource($event)"></app-resources-grid>
  </div>
</div>
