import { DataSort } from 'src/app/core/models/data-sort';

export const resourceSort: DataSort[] = [
  {
    id: 1,
    value: 'name',
    display: 'Resource',
    isDefault: true
  },
  {
    id: 2,
    value: 'resourceType',
    display: 'Type',
    isDefault: false
  },
  {
    id: 2,
    value: 'locationName',
    display: 'Location',
    isDefault: false
  }
];
