<ng-container *ngIf="usStates !== null">
  <form [formGroup]="form" (submit)="save()" class="ml-30 mt-30">
    @if (error !== null && !isSaving) {
      <div id="saveError" class="ml-20 mr-20 mb-20 mt-15 flex justify-center">
        <p>Error! Please try again later.</p>
      </div>
    }

    <div class="flex gap-40">
      <div formGroupName="locationProfile" class="flex flex-col gap-20 flex-1">
        <mat-form-field id="name" appearance="outline" subscriptSizing="dynamic" floatLabel="always">
          <mat-label>Location Name</mat-label>
          <input matInput id="locationName" formControlName="locationNameControl" data-test="iptName" />
          @if (form.controls['locationProfile'].controls['locationNameControl'].hasError('required')) {
            <mat-error id="nameRequired">Name required</mat-error>
          } @else if (form.controls['locationProfile'].controls['locationNameControl'].hasError('maxlength')) {
            <mat-error id="nameMaxLengthError">Must not exceed {{ maxLengthName }} characters</mat-error>
          } @else if (form.controls['locationProfile'].controls['locationNameControl'].hasError('duplicate')) {
            <mat-error id="nameDuplicateError">Location name already exists</mat-error>
          }
        </mat-form-field>

        <mat-form-field id="color" appearance="outline" subscriptSizing="dynamic" floatLabel="always">
          <mat-label>Color</mat-label>
          <mat-select
            hideSingleSelectionIndicator
            formControlName="colorControl"
            class="color-select"
            data-test="selHexColorCode">
            <mat-select-trigger>
              <div class="flex justify-between">
                {{ form.controls['locationProfile'].controls['colorControl'].value?.name }}
                <div
                  class="color-pill"
                  [style]="
                    'background-color: ' + form.controls['locationProfile'].controls['colorControl'].value?.hexCode
                  "></div>
              </div>
            </mat-select-trigger>
            @for (color of colors; track color) {
              <mat-option [value]="color">
                <div class="flex justify-start w-100p">
                  <div class="w-20x h-20x mr-8" [style]="'background-color: ' + color.hexCode"></div>
                  {{ color.name }}
                </div>
              </mat-option>
            }
          </mat-select>
          @if (form.controls['locationProfile'].controls['colorControl'].hasError('required')) {
            <mat-error id="colorRequired">Color required</mat-error>
          }
        </mat-form-field>

        <mat-form-field id="short-name" appearance="outline" subscriptSizing="dynamic" floatLabel="always">
          <mat-label>Location Short Name</mat-label>
          <input matInput id="locationShortName" formControlName="locationShortNameControl" data-test="iptShortName" />
          @if (form.controls['locationProfile'].controls['locationShortNameControl'].hasError('required')) {
            <mat-error id="shortNameRequired">Short Name required</mat-error>
          } @else if (form.controls['locationProfile'].controls['locationShortNameControl'].hasError('maxlength')) {
            <mat-error id="shortNameMaxLengthError">Must not exceed {{ maxLengthShortName }} characters</mat-error>
          } @else if (form.controls['locationProfile'].controls['locationShortNameControl'].hasError('duplicate')) {
            <mat-error id="shortNameDuplicateError">Short name already exists</mat-error>
          }
        </mat-form-field>

        <mat-form-field id="address1" appearance="outline" subscriptSizing="dynamic" floatLabel="always">
          <mat-label>Address Line 1</mat-label>
          <input matInput id="addressLine1Input" formControlName="addressLine1Control" data-test="iptAddress1" />
          @if (form.controls['locationProfile'].controls['addressLine1Control'].hasError('required')) {
            <mat-error id="addressLine1Required">Address required</mat-error>
          }
          @if (form.controls['locationProfile'].controls['addressLine1Control'].hasError('maxlength')) {
            <mat-error id="addressLine1MaxLengthError">Must not exceed {{ maxLengthAddress }} characters</mat-error>
          }
        </mat-form-field>

        <mat-form-field id="address2" appearance="outline" subscriptSizing="dynamic" floatLabel="always">
          <mat-label>Address Line 2</mat-label>
          <input matInput id="addressLine2Input" formControlName="addressLine2Control" data-test="iptAddress2" />
          @if (form.controls['locationProfile'].controls['addressLine2Control'].hasError('required')) {
            <mat-error id="addressLine2Required">Address required</mat-error>
          }
          @if (form.controls['locationProfile'].controls['addressLine2Control'].hasError('maxlength')) {
            <mat-error id="addressLine2MaxLengthError">Must not exceed {{ maxLengthAddress }} characters</mat-error>
          }
        </mat-form-field>

        <mat-form-field id="city" appearance="outline" subscriptSizing="dynamic" floatLabel="always">
          <mat-label>City</mat-label>
          <input matInput id="cityInput" formControlName="cityControl" data-test="iptCity" />
          @if (form.controls['locationProfile'].controls['cityControl'].hasError('required')) {
            <mat-error id="cityRequired">City required</mat-error>
          }
          @if (form.controls['locationProfile'].controls['cityControl'].hasError('maxlength')) {
            <mat-error id="cityMaxLengthError">Must not exceed {{ maxLengthCity }} characters</mat-error>
          }
        </mat-form-field>

        <mat-form-field id="state" appearance="outline" subscriptSizing="dynamic" floatLabel="always">
          <mat-label>State</mat-label>
          <mat-select formControlName="stateControl" data-test="selStateAbbreviation" class="state-select">
            @for (state of usStates; track state) {
              <mat-option [value]="state.abbreviation">
                <div>
                  {{ state.name }}
                </div>
              </mat-option>
            }
          </mat-select>
          @if (form.controls['locationProfile'].controls['stateControl'].hasError('required')) {
            <mat-error id="stateRequired">State required</mat-error>
          }
        </mat-form-field>

        <mat-form-field id="zip" appearance="outline" subscriptSizing="dynamic" floatLabel="always">
          <mat-label>Zip</mat-label>
          <input
            matInput
            id="zipInput"
            formControlName="zipControl"
            data-test="iptPostalCode"
            pattern="^[0-9]{5}(?:-[0-9]{4})?$"
            mask="00000-0000"
            [validation]="false"
            [dropSpecialCharacters]="false" />
          @if (form.controls['locationProfile'].controls['zipControl'].hasError('required')) {
            <mat-error id="zipRequired">Zip code required</mat-error>
          }
          @if (form.controls['locationProfile'].controls['zipControl'].hasError('pattern')) {
            <mat-error id="zipInvalidError">Invalid zip code</mat-error>
          }
        </mat-form-field>

        <mat-form-field id="phone" appearance="outline" subscriptSizing="dynamic" floatLabel="always">
          <mat-label>Phone</mat-label>
          <input
            matInput
            id="phoneInput"
            formControlName="phoneControl"
            mask="(000) 000-0000"
            data-test="iptPhoneNumber" />
          @if (form.controls['locationProfile'].controls['phoneControl'].hasError('required')) {
            <mat-error id="phoneRequired">Phone required</mat-error>
          }
          @if (form.controls['locationProfile'].controls['phoneControl'].hasError('mask')) {
            <mat-error id="phoneMaskError">Must be 10 digits</mat-error>
          }
        </mat-form-field>

        <mat-form-field id="fax" appearance="outline" subscriptSizing="dynamic" floatLabel="always">
          <mat-label>Fax</mat-label>
          <input matInput id="faxInput" formControlName="faxControl" mask="(000) 000-0000" data-test="iptFaxNumber" />
          @if (form.controls['locationProfile'].controls['faxControl'].hasError('required')) {
            <mat-error id="faxRequired">Fax required</mat-error>
          }
          @if (form.controls['locationProfile'].controls['faxControl'].hasError('mask')) {
            <mat-error id="faxMaskError">Must be 10 digits</mat-error>
          }
        </mat-form-field>

        <mat-form-field id="tax-id" appearance="outline" subscriptSizing="dynamic" floatLabel="always">
          <mat-label>Tax ID</mat-label>
          <input matInput id="taxIdInput" formControlName="taxIdControl" data-test="iptTaxPayerIdentificationNumber" />
          @if (form.controls['locationProfile'].controls['taxIdControl'].hasError('required')) {
            <mat-error id="taxIdRequired">Tax ID required</mat-error>
          }
          @if (form.controls['locationProfile'].controls['taxIdControl'].hasError('maxlength')) {
            <mat-error id="taxIdMaxLengthError">Must not exceed {{ maxLengthContact }} characters</mat-error>
          }
        </mat-form-field>

        <mat-form-field id="date" appearance="outline" subscriptSizing="dynamic" floatLabel="always">
          <mat-label>Inactive Date</mat-label>
          <input
            matInput
            [matDatepicker]="picker"
            id="inactiveDate"
            formControlName="inactiveDateControl"
            data-test="dteDeactivateOn"
            [min]="today" />
          <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
          @if (form.controls['locationProfile'].controls['inactiveDateControl'].invalid) {
            <mat-error id="dateInvalid">Date cannot be in the past</mat-error>
          }
        </mat-form-field>
      </div>

      <div class="flex-2 flex flex-col gap-20">
        <app-multiselect-autocomplete
          [multiSelectLabel]="'Appointment Type Quick Picks'"
          key="options"
          [data]="appointmentTypeQuickPicks"
          [selectedData]="selectedAppointmentTypeQuickPicks"
          (multiSelectResult)="onMultiSelectChange($event)">
        </app-multiselect-autocomplete>

        <ng-container formGroupName="locationProfile">
          <mat-form-field
            class="h-100p w-100p"
            id="tips"
            appearance="outline"
            subscriptSizing="dynamic"
            floatLabel="always">
            <mat-label>Scheduling Tips</mat-label>
            <textarea matInput id="tips" rows="32" formControlName="tipsControl" data-test="iptNotes"></textarea>
          </mat-form-field>
        </ng-container>
      </div>

      <div class="flex-2"></div>
    </div>

    <div class="btn-container flex justify-end align-center mb-20">
      <div>
        <button
          class="btn warn-button"
          id="btnCancel"
          color="warn"
          mat-stroked-button
          type="button"
          data-test="btnCancel"
          (click)="closeAddLocationEvent.emit()">
          Cancel
        </button>
      </div>
      <div class="ml-10">
        <button
          class="btn primary-solid-button"
          id="btnSubmit"
          color="primary"
          mat-raised-button
          type="submit"
          data-test="btnSave"
          [disabled]="isSaving">
          Save
        </button>
      </div>
    </div>
  </form>
</ng-container>

<app-progress-spinner-overlay
  [displayProgressSpinner]="isSaving"
  data-test="spinnerAddLocation"></app-progress-spinner-overlay>
