import { createEntityAdapter, EntityState, Update } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import * as baseState from 'src/app/core/models/base-state';
import { BaseState } from 'src/app/core/models/base-state';
import { resourceSort } from '../../data/resource-sort';
import { Resource } from '../../models/resource';
import { ResourceType } from '../../models/resource-type';
import * as resourcesActions from '../actions/resources.actions';

export interface ResourcesState extends EntityState<Resource> {
  baseState: baseState.BaseState;
  resourceTypes: ResourceType[] | null;
}

export const resourcesAdapter = createEntityAdapter<Resource>({
  selectId: resource => resource.resourceId
});

export const initialState = resourcesAdapter.getInitialState({
  baseState: new BaseState(resourceSort[0]),
  resourceTypes: null
});

export const resourcesFeatureKey = 'resources';

export const resourcesReducer = createReducer(
  initialState,
  on(resourcesActions.getResources, state => {
    return {
      ...state,
      baseState: baseState.setGet(state.baseState, false)
    };
  }),

  on(resourcesActions.getResourcesSuccess, (state, { resources }) => resourcesAdapter.addMany(resources, state)),

  on(resourcesActions.getResourcesSuccess, (state, { pageNumber, isAtEndOfData, totalRecordCount }) => {
    return {
      ...state,
      baseState: baseState.setGetSuccess(state.baseState, pageNumber, isAtEndOfData, totalRecordCount)
    };
  }),

  on(resourcesActions.getResourcesFailure, (state, { error }) => {
    return {
      ...state,
      baseState: baseState.setError(state.baseState, error)
    };
  }),

  on(
    resourcesActions.getResources,
    resourcesActions.setPageSize,
    resourcesActions.setPageNumber,
    resourcesActions.getResourcesSearch,
    resourcesActions.setSortBy,
    resourcesActions.setSortDirection,
    resourcesActions.setIsActive,
    state => resourcesAdapter.removeAll(state)
  ),

  on(resourcesActions.setPageNumber, (state, { pageNumber }) => {
    return {
      ...state,
      baseState: baseState.setPageNumber(state.baseState, pageNumber)
    };
  }),

  on(resourcesActions.setPageSize, (state, { pageSize }) => {
    return {
      ...state,
      baseState: baseState.setPageSize(state.baseState, pageSize)
    };
  }),

  on(resourcesActions.getResourcesSearch, (state, { searchTerm }) => {
    return {
      ...state,
      baseState: baseState.setSearch(state.baseState, searchTerm)
    };
  }),

  on(resourcesActions.setSortDirection, (state, { sortDirection }) => {
    return {
      ...state,
      baseState: baseState.setSortDirection(state.baseState, sortDirection)
    };
  }),

  on(resourcesActions.setSortBy, (state, { sortBy }) => {
    return {
      ...state,
      baseState: baseState.setSortBy(state.baseState, sortBy)
    };
  }),

  on(resourcesActions.setIsActive, (state, { isActive }) => {
    return {
      ...state,
      baseState: baseState.setIsActive(state.baseState, isActive)
    };
  }),

  on(resourcesActions.resetFilters, state => {
    return {
      ...state,
      baseState: baseState.resetFilters(state.baseState, resourceSort[0])
    };
  }),

  on(resourcesActions.updateResource, state => {
    return {
      ...state,
      baseState: baseState.setUpdate(state.baseState)
    };
  }),

  on(resourcesActions.addResourceSuccess, resourcesActions.updateResourceSuccess, state => {
    return {
      ...state,
      baseState: baseState.setError(state.baseState, null)
    };
  }),

  on(resourcesActions.addResourceFailure, resourcesActions.updateResourceFailure, (state, { error }) => {
    return {
      ...state,
      baseState: baseState.setError(state.baseState, error)
    };
  }),

  on(resourcesActions.getResourceTypes, state => {
    return {
      ...state,
      resourceTypes: null
    };
  }),

  on(resourcesActions.getResourceTypesSuccess, (state, { resourceTypes }) => {
    return {
      ...state,
      resourceTypes
    };
  }),

  on(resourcesActions.getResourceTypesFailure, (state, { error }) => {
    return {
      ...state,
      baseState: baseState.setError(state.baseState, error)
    };
  }),

  on(resourcesActions.getResourceByIdSuccess, (state, { resource }) => {
    const i = state.ids.findIndex((fi: any) => fi == resource.resourceId);
    if (i < 0) return resourcesAdapter.addOne(resource, state);

    const updatedResource: Update<Resource> = {
      id: resource.resourceId,
      changes: {
        ...resource
      }
    };

    return resourcesAdapter.updateOne(updatedResource, state);
  }),

  on(resourcesActions.getResourceByIdSuccess, state => {
    return {
      ...state,
      baseState: baseState.setError(state.baseState, null)
    };
  }),

  on(resourcesActions.getResourceByIdFailure, (state, { error }) => {
    return {
      ...state,
      baseState: baseState.setError(state.baseState, error)
    };
  })
);
