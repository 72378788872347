import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { SortDirection } from 'src/app/core/enums/sort-direction';
import { DataSort } from 'src/app/core/models/data-sort';
import { providerSort } from '../../data/provider-sort';
import { ProviderResource } from '../../models/provider';
import {
  getProviders,
  getProvidersSearch,
  navigateToAddProvider,
  resetFilters,
  setIsActive,
  setPageNumber,
  setPageSize,
  setSortBy,
  setSortDirection
} from '../../store/actions/providers.actions';
import * as selectors from '../../store/selectors/providers.selectors';
import { ProvidersFilterBarComponent } from '../../ui/providers-filter-bar/providers-filter-bar.component';
import { ProvidersGridComponent } from '../../ui/providers-grid/providers-grid.component';

@Component({
  selector: 'app-providers',
  standalone: true,
  imports: [ProvidersGridComponent, ProvidersFilterBarComponent, CommonModule],
  templateUrl: './providers.component.html',
  styleUrl: './providers.component.scss'
})
export class ProvidersComponent implements OnDestroy, OnInit {
  providers$: Observable<ProviderResource[] | null>;
  isLoading$: Observable<boolean>;
  error$: Observable<any>;
  isAtEndOfData$: Observable<boolean>;
  sortDirection$: Observable<SortDirection>;
  sortBy$: Observable<DataSort>;
  totalRecordCount$: Observable<number>;
  currentPage$: Observable<number>;
  pageSize$: Observable<number>;
  searchTerm$: Observable<string>;
  isActive$: Observable<boolean | null>;
  isEditing: boolean = false;
  hasFilters: boolean = false;

  constructor(private store: Store) {
    this.providers$ = this.store.select(selectors.selectProviders);
    this.isLoading$ = this.store.select(selectors.selectIsLoading);
    this.error$ = this.store.select(selectors.selectError);
    this.isAtEndOfData$ = this.store.select(selectors.selectIsAtEndOfData);
    this.sortDirection$ = this.store.select(selectors.selectSortDirection);
    this.sortBy$ = this.store.select(selectors.selectSortBy);
    this.totalRecordCount$ = this.store.select(selectors.selectTotalRecordCount);
    this.currentPage$ = this.store.select(selectors.selectPageNumber);
    this.pageSize$ = this.store.select(selectors.selectPageSize);
    this.searchTerm$ = this.store.select(selectors.selectSearchTerm);
    this.isActive$ = this.store.select(selectors.selectIsActive);
  }

  ngOnInit(): void {
    this.store.dispatch(getProviders());
  }

  onPage(pageNumber: number): void {
    this.store.dispatch(setPageNumber({ pageNumber }));
  }

  onPageSize = (pageSize: number) => this.store.dispatch(setPageSize({ pageSize }));

  onSearch(searchTerm: string | null): void {
    this.store.dispatch(getProvidersSearch({ searchTerm }));
    if (searchTerm !== null) {
      this.hasFilters = true;
    } else {
      this.hasFilters = false;
    }
  }

  onActiveFiltersChange(isActive: boolean): void {
    this.store.dispatch(setIsActive({ isActive }));
    if (isActive !== true) {
      this.hasFilters = true;
    } else {
      this.hasFilters = false;
    }
  }

  onSort(sort: Sort): void {
    this.store.dispatch(
      setSortDirection({
        sortDirection: sort.direction === 'desc' ? SortDirection.Descending : SortDirection.Ascending
      })
    );

    let dataSort: DataSort | undefined = providerSort.find(f => f.value === sort.active);
    if (dataSort === undefined) {
      dataSort = providerSort.find(f => f.isDefault);
      this.store.dispatch(setSortBy({ sortBy: dataSort! }));
    } else {
      this.store.dispatch(setSortBy({ sortBy: dataSort }));
    }
  }

  addProvider() {
    this.isEditing = true;
    this.store.dispatch(navigateToAddProvider());
  }

  ngOnDestroy(): void {
    if (!this.isEditing) this.store.dispatch(resetFilters());
  }
}
