import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { AuthService } from '../services/auth/auth.service';

export const authGuard: CanActivateFn = () => {
  const authService = inject(AuthService);

  return authService
    .getAuthorization()
    .then(result => {
      if (!result) {
        authService.redirectToLogin();
      }
      return result;
    })
    .catch(error => {
      console.log(error);
      authService.redirectToLogin();
      return false;
    });
};
