import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { NGXLogger } from 'ngx-logger';
import { catchError, map, mergeMap, of, tap } from 'rxjs';
import { selectParams } from 'src/app/core/router/store/router.selectors';
import { AppointmentTypeCategoriesService } from '../../service/appointment-type-categories/appointment-type-categories.service';
import { AppointmentTypeService } from '../../service/appointment-types/appointment-types.service';
import * as appointmentTypeActions from '../../store/actions/appointment-types.actions';
import { getAppointmentTypeCategories } from '../../store/actions/appointment-types.actions';
import * as appointmentTypeSelector from '../../store/selector/appointment-types.selector';

@Injectable()
export class AppointmentTypeEffects {
  constructor(
    private actions$: Actions,
    private appointmentTypeService: AppointmentTypeService,
    private appointmentTypeCategoriesService: AppointmentTypeCategoriesService,
    private store: Store,
    private logger: NGXLogger,
    private router: Router
  ) {}

  getAppointmentTypes$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        appointmentTypeActions.getAppointmentTypes,
        appointmentTypeActions.setPageNumber,
        appointmentTypeActions.setPageSize,
        appointmentTypeActions.getAppointmentTypeSearch,
        appointmentTypeActions.setSortBy,
        appointmentTypeActions.setIsActive,
        appointmentTypeActions.getAppointmentTypeQuickPicks
      ),
      concatLatestFrom(() => [
        this.store.select(appointmentTypeSelector.selectPageNumber),
        this.store.select(appointmentTypeSelector.selectSortDirection),
        this.store.select(appointmentTypeSelector.selectSortBy),
        this.store.select(appointmentTypeSelector.selectPageSize),
        this.store.select(appointmentTypeSelector.selectSearchTerm),
        this.store.select(appointmentTypeSelector.selectIsActive)
      ]),
      mergeMap(([, pageNumber, sortDirection, sortBy, pageSize, searchTerm, isActive]) => {
        return this.appointmentTypeService
          .postSearchAppointmentTypes(pageNumber, sortDirection, sortBy, pageSize, searchTerm, isActive)
          .pipe(
            map(response => {
              let isAtEndOfData = false;
              const totalRecordCount = response.totalRecordCount;
              let currentPage = pageNumber;
              let appointmentTypes = response.result;

              if (appointmentTypes == null || appointmentTypes.length == 0) {
                appointmentTypes = [];
                currentPage--;
                isAtEndOfData = true;
              }

              return appointmentTypeActions.getAppointmentTypesSuccess({
                appointmentTypes,
                pageNumber: currentPage <= 0 ? 1 : currentPage,
                isAtEndOfData,
                totalRecordCount
              });
            }),
            catchError(err => {
              this.logger.error(err);
              return of(appointmentTypeActions.getAppointmentTypesFailure({ error: err.error?.errors }));
            })
          );
      })
    );
  });

  getAppointmentTypeCategories$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getAppointmentTypeCategories),
      mergeMap(() => {
        return this.appointmentTypeCategoriesService.getAppointmentTypeCategories().pipe(
          map(appointmentTypeCategories => {
            appointmentTypeCategories.sort(function (a, b) {
              return a.sortOrder - b.sortOrder;
            });
            return appointmentTypeActions.getAppointmentTypeCategoriesSuccess({ appointmentTypeCategories });
          }),
          catchError(err => {
            this.logger.error(err);
            return of(appointmentTypeActions.getAppointmentTypeCategoriesFailure({ error: err.error?.errors }));
          })
        );
      })
    );
  });

  addAppointmentType$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(appointmentTypeActions.addAppointmentType),
      mergeMap(action => {
        return this.appointmentTypeService.postAppointmentTypes(action.newAppointmentType).pipe(
          map(() => {
            return appointmentTypeActions.addAppointmentTypeSuccess();
          }),
          tap(() => this.router.navigate(['appointment-types'])),
          catchError(err => {
            this.logger.error(err);
            return of(appointmentTypeActions.addAppointmentTypeFailure({ error: err.error?.errors }));
          })
        );
      })
    );
  });

  getAppointmentTypeById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(appointmentTypeActions.getAppointmentTypeById),
      concatLatestFrom(() => [this.store.select(selectParams)]),
      mergeMap(([, { appointmentTypeId }]) => {
        return this.appointmentTypeService.getAppointmentTypeById(appointmentTypeId).pipe(
          map(appointmentType => {
            return appointmentTypeActions.getAppointmentTypeByIdSuccess({ appointmentType });
          }),
          catchError(err => {
            this.logger.error(err);
            return of(appointmentTypeActions.getAppointmentTypeByIdFailure({ error: err.error?.errors }));
          })
        );
      })
    );
  });

  updateAppointmentType$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(appointmentTypeActions.updateAppointmentType),
      mergeMap(action => {
        return this.appointmentTypeService.updateAppointmentType(action.appointmentType).pipe(
          map(() => {
            return appointmentTypeActions.updateAppointmentTypeSuccess();
          }),
          tap(() => this.router.navigate(['appointment-types'])),
          catchError(err => {
            this.logger.error(err);
            return of(appointmentTypeActions.updateAppointmentTypeFailure({ error: err.error?.errors }));
          })
        );
      })
    );
  });
}
