import { environment } from '../../../environments/environment';
import { SortDirection } from '../enums/sort-direction';
import { DataSort } from './data-sort';

export interface ISearchParameters {
  pageNumber: number;
  pageSize: number;
  sortDirection: SortDirection;
  sortColumn?: string;
  searchTerm: string | null;
  isActive: boolean | null;
}

export class SearchParameters implements ISearchParameters {
  pageNumber: number;
  pageSize: number;
  sortDirection: SortDirection;
  sortColumn?: string;
  searchTerm: string | null;
  isActive: boolean | null;

  constructor(
    pageNumber: number,
    sortDirection: SortDirection,
    sortColumn: DataSort | null,
    pageSize: number | null = null,
    searchTerm: string | null,
    isActive: boolean | null
  ) {
    this.pageNumber = pageNumber;
    this.pageSize = pageSize === null ? environment.pageSize : pageSize;
    this.sortDirection = sortDirection;
    this.searchTerm = searchTerm;
    this.isActive = isActive;

    if (sortColumn?.value !== undefined && sortColumn?.value !== null && sortColumn.value.trim().length > 0) {
      this.sortColumn = sortColumn.value;
    }
  }
}
