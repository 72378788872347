<div class="form-container">
  <form [formGroup]="form" (submit)="save()" class="mt-30">
    @if (error !== null && isSaving === false) {
      <div id="saveError" class="ml-20 mr-20 mb-20 mt-15 flex justify-center">
        <p>{{ error[0] }}</p>
      </div>
    }
    <div class="mr-20 ml-20 mt-20 flex flex-col gap-20" id="form-container">
      <mat-form-field appearance="outline" subscriptSizing="dynamic" floatLabel="always">
        <mat-label>Name of Appointment Type</mat-label>
        <input
          matInput
          #appointmentTypeName
          id="appointmentTypeName"
          formControlName="appointmentTypeNameControl"
          [maxLength]="maxTypeNameLength"
          data-test="iptName" />
        @if (form.controls['appointmentTypeNameControl'].hasError('required')) {
          <mat-error id="nameRequired">Name required</mat-error>
        }
      </mat-form-field>

      <div class="flex gap-20">
        <mat-form-field class="flex-1" appearance="outline" subscriptSizing="dynamic" floatLabel="always">
          <mat-label>Duration in Minutes</mat-label>
          <input
            matInput
            #duration
            id="duration"
            formControlName="durationControl"
            appNumbersOnly
            [allowDecimals]="false"
            data-test="iptDurationInMinutes" />
          @if (form.controls['durationControl'].hasError('required')) {
            <mat-error id="durationRequiredError">Duration required</mat-error>
          }
          @if (form.controls['durationControl'].hasError('min')) {
            <mat-error id="durationMinRequired">Duration must be greater than 5</mat-error>
          }
          @if (form.controls['durationControl'].hasError('max')) {
            <mat-error id="durationMaxRequired">Duration must be less than 480</mat-error>
          }
        </mat-form-field>

        <mat-form-field class="flex-2" appearance="outline" subscriptSizing="dynamic" floatLabel="always">
          <mat-label>Category</mat-label>
          <mat-select formControlName="categoryControl" data-test="selAppointmentCategoryDescription">
            @for (category of categories; track category) {
              <mat-option [value]="category.appointmentTypeCategoryLookupId">{{ category.description }}</mat-option>
            }
          </mat-select>
          @if (form.controls['categoryControl'].hasError('required')) {
            <mat-error id="categoryRequired">Category required</mat-error>
          }
          @if (form.controls['durationControl'].invalid || form.controls['colorControl'].invalid) {
            <mat-error></mat-error>
          }
        </mat-form-field>

        <mat-form-field class="flex-1" appearance="outline" subscriptSizing="dynamic" floatLabel="always">
          <mat-label>Color</mat-label>
          <mat-select
            hideSingleSelectionIndicator
            formControlName="colorControl"
            data-test="HexColorCode"
            class="color-select">
            <mat-select-trigger>
              <div class="flex justify-between">
                {{ form.controls['colorControl'].value?.name }}
                <div
                  class="color-pill ml-15 mr-10"
                  [style]="'background-color: ' + form.controls['colorControl'].value?.hexCode"></div>
              </div>
            </mat-select-trigger>
            @for (color of categoryColors; track color) {
              <mat-option [value]="color">
                <div class="flex justify-start">
                  <div class="w-20x h-20x mr-8" [style]="'background-color: ' + color.hexCode"></div>
                  {{ color.name }}
                </div>
              </mat-option>
            }
          </mat-select>
          @if (form.controls['colorControl'].hasError('required')) {
            <mat-error id="colorRequired">Color required</mat-error>
          }
        </mat-form-field>
      </div>

      <mat-form-field appearance="outline" subscriptSizing="dynamic" floatLabel="always" class="h-190x">
        <mat-label>Instructions</mat-label>
        <textarea
          matInput
          cdkTextareaAutosize
          cdkAutosizeMaxRows="6"
          #instructions
          [maxlength]="maxInstructionsLength"
          formControlName="instructionsControl"
          data-test="iptInstructions"></textarea>
      </mat-form-field>

      <mat-form-field appearance="outline" subscriptSizing="dynamic" class="w-200x" floatLabel="always">
        <mat-label>Inactive Date</mat-label>
        <input
          matInput
          [matDatepicker]="picker"
          #inactiveDate
          id="inactiveDate"
          formControlName="inactiveDateControl"
          data-test="dteDeactivateOn"
          [min]="today" />
        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </div>

    <div class="btn-container flex justify-end align-center w-100p">
      <div>
        <button
          class="btn warn-button"
          id="btnCancel"
          color="warn"
          mat-stroked-button
          type="button"
          data-test="btnCancel"
          (click)="closeAddAppointmentEvent.emit()">
          Cancel
        </button>
      </div>

      <ng-container *ngIf="appointmentType === null || appointmentType.isActive">
        <div class="ml-10">
          <button
            class="btn primary-solid-button"
            id="btnSubmit"
            color="primary"
            mat-raised-button
            type="submit"
            data-test="btnSave"
            [disabled]="isSaving">
            Save
          </button>
        </div>
      </ng-container>
    </div>
  </form>
</div>

<app-progress-spinner-overlay
  [displayProgressSpinner]="isSaving"
  data-test="spinnerAddLocation"></app-progress-spinner-overlay>
