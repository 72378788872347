import { EntityState, Update, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import * as baseState from 'src/app/core/models/base-state';
import { appointmentTypeSort } from '../../data/appointment-type-sort';
import { AppointmentType } from '../../models/appointment-type';
import { AppointmentTypeCategory } from '../../models/appointment-type-category';
import * as appointmentTypeActions from '../../store/actions/appointment-types.actions';

export interface AppointmentTypeState extends EntityState<AppointmentType> {
  baseState: baseState.BaseState;
  appointmentTypeCategories: AppointmentTypeCategory[] | null;
}

export const appointmentTypeAdapter = createEntityAdapter<AppointmentType>({
  selectId: appointmentType => appointmentType.appointmentTypeId
});

export const initialState = appointmentTypeAdapter.getInitialState({
  baseState: new baseState.BaseState(appointmentTypeSort[0]),
  appointmentTypeCategories: null
});

export const appointmentTypeFeatureKey = 'appointment-types';

export const appointmentTypeReducer = createReducer(
  initialState,
  on(appointmentTypeActions.getAppointmentTypes, state => {
    return {
      ...state,
      baseState: baseState.setGet(state.baseState, false)
    };
  }),

  on(appointmentTypeActions.getAppointmentTypesSuccess, (state, { appointmentTypes }) =>
    appointmentTypeAdapter.addMany(appointmentTypes, state)
  ),

  on(appointmentTypeActions.getAppointmentTypesSuccess, (state, { pageNumber, isAtEndOfData, totalRecordCount }) => {
    return {
      ...state,
      baseState: baseState.setGetSuccess(state.baseState, pageNumber, isAtEndOfData, totalRecordCount)
    };
  }),

  on(appointmentTypeActions.getAppointmentTypesFailure, (state, { error }) => {
    return {
      ...state,
      baseState: baseState.setError(state.baseState, error)
    };
  }),

  on(
    appointmentTypeActions.getAppointmentTypes,
    appointmentTypeActions.setPageSize,
    appointmentTypeActions.setPageNumber,
    appointmentTypeActions.getAppointmentTypeSearch,
    appointmentTypeActions.setSortBy,
    appointmentTypeActions.setSortDirection,
    appointmentTypeActions.setIsActive,
    state => appointmentTypeAdapter.removeAll(state)
  ),

  on(appointmentTypeActions.setPageNumber, (state, { pageNumber }) => {
    return {
      ...state,
      baseState: baseState.setPageNumber(state.baseState, pageNumber)
    };
  }),

  on(appointmentTypeActions.setPageSize, (state, { pageSize }) => {
    return {
      ...state,
      baseState: baseState.setPageSize(state.baseState, pageSize)
    };
  }),

  on(appointmentTypeActions.getAppointmentTypeSearch, (state, { searchTerm }) => {
    return {
      ...state,
      baseState: baseState.setSearch(state.baseState, searchTerm)
    };
  }),

  on(appointmentTypeActions.setSortDirection, (state, { sortDirection }) => {
    return {
      ...state,
      baseState: baseState.setSortDirection(state.baseState, sortDirection)
    };
  }),

  on(appointmentTypeActions.setSortBy, (state, { sortBy }) => {
    return {
      ...state,
      baseState: baseState.setSortBy(state.baseState, sortBy)
    };
  }),

  on(appointmentTypeActions.setIsActive, (state, { isActive }) => {
    return {
      ...state,
      baseState: baseState.setIsActive(state.baseState, isActive)
    };
  }),

  on(appointmentTypeActions.resetFilters, state => {
    return {
      ...state,
      baseState: baseState.resetFilters(state.baseState)
    };
  }),

  on(appointmentTypeActions.getAppointmentTypeCategories, state => {
    return {
      ...state,
      appointmentTypeCategories: null
    };
  }),

  on(appointmentTypeActions.getAppointmentTypeCategoriesSuccess, (state, { appointmentTypeCategories }) => {
    return {
      ...state,
      appointmentTypeCategories
    };
  }),

  on(appointmentTypeActions.getAppointmentTypeCategoriesFailure, (state, { error }) => {
    return {
      ...state,
      appointmentTypeCategories: null,
      baseState: baseState.setError(state.baseState, error)
    };
  }),

  on(appointmentTypeActions.addAppointmentType, appointmentTypeActions.updateAppointmentType, state => {
    return {
      ...state,
      baseState: baseState.setError(state.baseState, null)
    };
  }),

  on(appointmentTypeActions.addAppointmentTypeSuccess, appointmentTypeActions.updateAppointmentTypeSuccess, state => {
    return {
      ...state,
      baseState: baseState.setError(state.baseState, null)
    };
  }),

  on(
    appointmentTypeActions.addAppointmentTypeFailure,
    appointmentTypeActions.updateAppointmentTypeFailure,
    (state, { error }) => {
      return {
        ...state,
        baseState: baseState.setError(state.baseState, error)
      };
    }
  ),

  on(appointmentTypeActions.getAppointmentTypeByIdSuccess, (state, { appointmentType }) => {
    const i = state.ids.findIndex((fi: any) => fi == appointmentType.appointmentTypeId);
    if (i < 0) return appointmentTypeAdapter.addOne(appointmentType, state);

    const updatedAppointmentType: Update<AppointmentType> = {
      id: appointmentType.appointmentTypeId,
      changes: {
        ...appointmentType
      }
    };

    return appointmentTypeAdapter.updateOne(updatedAppointmentType, state);
  }),

  on(appointmentTypeActions.getAppointmentTypeByIdSuccess, state => {
    return {
      ...state,
      baseState: baseState.setError(state.baseState, null)
    };
  }),

  on(appointmentTypeActions.getAppointmentTypeByIdFailure, (state, { error }) => {
    return {
      ...state,
      baseState: baseState.setError(state.baseState, error)
    };
  }),

  on(appointmentTypeActions.getAppointmentTypeQuickPicks, state => {
    return {
      ...state,
      baseState: baseState.setPageSize(state.baseState, 100, true)
    };
  })
);
