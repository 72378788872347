import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { SortDirection } from 'src/app/core/enums/sort-direction';
import { DataSort } from 'src/app/core/models/data-sort';
import { locationSort } from '../../data/location-sort';
import { ApptLocation } from '../../models/location';
import {
  getLocations,
  getLocationsSearch,
  resetFilters,
  setIsActive,
  setPageNumber,
  setPageSize,
  setSortBy,
  setSortDirection
} from '../../store/actions/locations.actions';
import * as selectors from '../../store/selectors/locations.selectors';
import { LocationsFilterBarComponent } from '../../ui/locations-filter-bar/locations-filter-bar.component';
import { LocationsGridComponent } from '../../ui/locations-grid/locations-grid.component';

@Component({
  selector: 'app-locations',
  standalone: true,
  imports: [LocationsGridComponent, LocationsFilterBarComponent, CommonModule],
  templateUrl: './locations.component.html',
  styleUrl: './locations.component.scss'
})
export class LocationsComponent implements OnInit, OnDestroy {
  locations$: Observable<ApptLocation[] | null>;
  isLoading$: Observable<boolean>;
  error$: Observable<any>;
  isAtEndOfData$: Observable<boolean>;
  sortDirection$: Observable<SortDirection>;
  sortBy$: Observable<DataSort>;
  totalRecordCount$: Observable<number>;
  currentPage$: Observable<number>;
  pageSize$: Observable<number>;
  searchTerm$: Observable<string>;
  isActive$: Observable<boolean | null>;
  isEditing: boolean = false;

  constructor(
    private store: Store,
    private router: Router
  ) {
    this.locations$ = this.store.select(selectors.selectLocations);
    this.isLoading$ = this.store.select(selectors.selectIsLoading);
    this.error$ = this.store.select(selectors.selectError);
    this.isAtEndOfData$ = this.store.select(selectors.selectIsAtEndOfData);
    this.sortDirection$ = this.store.select(selectors.selectSortDirection);
    this.sortBy$ = this.store.select(selectors.selectSortBy);
    this.totalRecordCount$ = this.store.select(selectors.selectTotalRecordCount);
    this.currentPage$ = this.store.select(selectors.selectPageNumber);
    this.pageSize$ = this.store.select(selectors.selectPageSize);
    this.searchTerm$ = this.store.select(selectors.selectSearchTerm);
    this.isActive$ = this.store.select(selectors.selectIsActive);
  }

  ngOnInit(): void {
    this.store.dispatch(getLocations());
  }

  onPage(pageNumber: number): void {
    this.store.dispatch(setPageNumber({ pageNumber }));
  }

  onPageSize = (pageSize: number) => this.store.dispatch(setPageSize({ pageSize }));

  onSearch(searchTerm: string | null): void {
    this.store.dispatch(getLocationsSearch({ searchTerm }));
  }

  onIncludeInactiveChange(includeInactive: boolean): void {
    this.store.dispatch(setIsActive({ isActive: includeInactive ? null : true }));
  }

  onSort(sort: Sort): void {
    this.store.dispatch(
      setSortDirection({
        sortDirection: sort.direction === 'desc' ? SortDirection.Descending : SortDirection.Ascending
      })
    );

    let dataSort: DataSort | undefined = locationSort.find(f => f.value === sort.active);
    if (dataSort === undefined) {
      dataSort = locationSort.find(f => f.isDefault);
      this.store.dispatch(setSortBy({ sortBy: dataSort! }));
    } else {
      this.store.dispatch(setSortBy({ sortBy: dataSort }));
    }
  }

  addLocation() {
    this.isEditing = true;
    this.router.navigate(['locations/add-location']);
  }

  updateLocation(locationId: number) {
    this.isEditing = true;
    this.router.navigate(['locations/update-location', locationId]);
  }

  ngOnDestroy(): void {
    if (!this.isEditing) this.store.dispatch(resetFilters());
  }
}
