import { Routes } from '@angular/router';
import { AddAppointmentTypeComponent } from './appointment-types/feature/add-appointment-type/add-appointment-type.component';
import { AppointmentTypesComponent } from './appointment-types/feature/appointment-types/appointment-types.component';
import { UpdateAppointmentTypeComponent } from './appointment-types/feature/update-appointment-type/update-appointment-type.component';
import { authGuard } from './core/guards/auth.guard';
import { ErrorPageComponent } from './error-page/error-page.component';
import { HomeComponent } from './home/home.component';
import { AddLocationComponent } from './locations/feature/add-location/add-location.component';
import { LocationsComponent } from './locations/feature/locations/locations.component';
import { UpdateLocationComponent } from './locations/feature/update-location/update-location.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { AddResourceComponent } from './resources/feature/add-resource/add-resource.component';
import { ResourcesComponent } from './resources/feature/resources/resources.component';
import { UpdateResourceComponent } from './resources/feature/update-resource/update-resource.component';
import { LayoutWithNavComponent } from './shared/component/layout/layout-with-nav/layout-with-nav.component';
import { ProvidersComponent } from './providers/feature/providers/providers.component';
import { AddProviderComponent } from './providers/feature/add-provider/add-provider.component';

export const routes: Routes = [
  {
    path: '',
    component: LayoutWithNavComponent,
    children: [
      {
        path: '',
        component: HomeComponent,
        canActivate: [authGuard]
      },
      {
        path: 'appointment-types',
        component: AppointmentTypesComponent,
        canActivate: [authGuard]
      },
      {
        path: 'appointment-types/add-appointment-type',
        component: AddAppointmentTypeComponent,
        canActivate: [authGuard]
      },
      {
        path: 'appointment-types/update-appointment-type/:appointmentTypeId',
        component: UpdateAppointmentTypeComponent,
        canActivate: [authGuard]
      },
      {
        path: 'locations',
        component: LocationsComponent,
        canActivate: [authGuard]
      },
      {
        path: 'locations/add-location',
        component: AddLocationComponent,
        canActivate: [authGuard]
      },
      {
        path: 'locations/update-location/:locationId',
        component: UpdateLocationComponent,
        canActivate: [authGuard]
      },
      {
        path: 'resources',
        component: ResourcesComponent,
        canActivate: [authGuard]
      },
      {
        path: 'resources/add-resource',
        component: AddResourceComponent,
        canActivate: [authGuard]
      },
      {
        path: 'resources/update-resource/:resourceId',
        component: UpdateResourceComponent,
        canActivate: [authGuard]
      },
      {
        path: 'providers',
        component: ProvidersComponent,
        canActivate: [authGuard]
      },
      {
        path: 'providers/add-provider',
        component: AddProviderComponent,
        canActivate: [authGuard]
      },
      {
        path: 'error',
        component: ErrorPageComponent,
        canActivate: [authGuard]
      },
      {
        path: '**',
        component: PageNotFoundComponent,
        canActivate: [authGuard]
      }
    ]
  }
];
