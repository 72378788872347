import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { map, Observable, startWith } from 'rxjs';
import { MultiSelectAutoCompleteItem } from '../../models/multiselect-autocomplete-item';

@Component({
  selector: 'app-multiselect-autocomplete',
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    MatChipsModule,
    MatCheckboxModule,
    MatIconModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    MatChipsModule,
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  templateUrl: './multiselect-autocomplete.component.html',
  styleUrl: './multiselect-autocomplete.component.scss'
})
export class MultiselectAutocompleteComponent {
  @Input() placeholder: string = 'Select Data';
  @Input() data: MultiSelectAutoCompleteItem[] = [];
  @Input() set selectedData(value: MultiSelectAutoCompleteItem[]) {
    if (value !== null && value.length > 0) value.forEach(fe => this.toggleSelection(fe));
  }
  @Input() key: string = '';
  @Input() multiSelectLabel: string = '';
  @Output() multiSelectResult = new EventEmitter<{ key: string; data: MultiSelectAutoCompleteItem[] }>();

  selectControl = new FormControl();
  selectedItems: MultiSelectAutoCompleteItem[] = [];
  filteredData: Observable<MultiSelectAutoCompleteItem[]>;
  filterString: string = '';
  isClosed: boolean = true;

  @ViewChild('auto') autocomplete!: MatAutocomplete;

  constructor() {
    this.filteredData = this.selectControl.valueChanges.pipe(
      startWith<string>(''),
      map(value => (typeof value === 'string' ? value : this.filterString)),
      map(filter => this.filter(filter))
    );
  }

  filter = (filter: string): Array<MultiSelectAutoCompleteItem> => {
    this.filterString = filter;
    if (filter.length > 0) {
      return this.data.filter(option => {
        return option.name.toLowerCase().indexOf(filter.toLowerCase()) >= 0;
      });
    } else {
      return this.data.slice();
    }
  };

  displayFn = (): string => this.filterString;

  toggleSelection = (data: MultiSelectAutoCompleteItem): void => {
    data.selected = !data.selected;
    if (data.selected === true) {
      this.selectedItems.push(data);
    } else {
      const i = this.selectedItems.findIndex(value => value.name === data.name);
      this.selectedItems.splice(i, 1);
    }
    this.selectControl.setValue(this.selectedItems);
    this.emitAdjustedData();
  };

  removeChip = (data: MultiSelectAutoCompleteItem): void => {
    this.toggleSelection(data);
  };

  onEnter() {
    const t = this.data.filter(option => {
      return option.name.toLowerCase().indexOf(this.filterString.toLowerCase()) >= 0;
    });
    if (t.length === 1) {
      this.toggleSelection(t[0]);
      this.selectControl.setValue('');
    }
  }

  emitAdjustedData = (): void => {
    const results: MultiSelectAutoCompleteItem[] = [];
    this.selectedItems.forEach((data: MultiSelectAutoCompleteItem) => {
      results.push(data);
    });
    this.multiSelectResult.emit({ key: this.key, data: results });
  };

  onArrowClick(isClosed: boolean, event: Event) {
    this.isClosed = isClosed;
    this.autocomplete.showPanel = false;
    if (isClosed) event.stopPropagation();
  }

  toggleArrow(isClosed: boolean) {
    this.isClosed = isClosed;
  }
}
