import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ApptLocation } from 'src/app/locations/models/location';
import * as locationActions from 'src/app/locations/store/actions/locations.actions';
import { selectLocations } from 'src/app/locations/store/selectors/locations.selectors';
import { ProgressSpinnerComponent } from 'src/app/shared/component/progress-spinner/progress-spinner.component';
import { selectUserId } from 'src/app/user/store/selectors/user.selectors';
import { Resource } from '../../models/resource';
import { ResourceType } from '../../models/resource-type';
import * as resourceActions from '../../store/actions/resources.actions';
import { selectError, selectResourceTypes } from '../../store/selectors/resources.selectors';
import { UpsertResourceFormComponent } from '../../ui/upsert-resource-form/upsert-resource-form.component';

@Component({
  selector: 'app-add-resource',
  standalone: true,
  imports: [CommonModule, LetDirective, ProgressSpinnerComponent, UpsertResourceFormComponent],
  templateUrl: './add-resource.component.html',
  styleUrl: './add-resource.component.scss'
})
export class AddResourceComponent implements OnInit, OnDestroy {
  saveError$: Observable<string[] | null>;
  resourceTypes$: Observable<ResourceType[] | null>;
  locations$: Observable<ApptLocation[] | null>;
  modifiedByUserId$: Observable<number | null>;

  constructor(private store: Store) {
    this.saveError$ = this.store.select(selectError);
    this.resourceTypes$ = this.store.select(selectResourceTypes);
    this.locations$ = this.store.select(selectLocations);
    this.modifiedByUserId$ = this.store.select(selectUserId);
  }

  ngOnInit(): void {
    this.store.dispatch(locationActions.setPageSize({ pageSize: 100 }));
    this.store.dispatch(resourceActions.getResourceTypes());
  }

  addResource(resourceToAdd: Resource) {
    this.store.dispatch(resourceActions.addResource({ resource: resourceToAdd }));
  }

  cancel() {
    this.store.dispatch(resourceActions.navigateToResourcesGrid());
  }

  ngOnDestroy(): void {
    this.store.dispatch(locationActions.resetFilters());
  }
}
