import { createFeatureSelector, createSelector } from '@ngrx/store';
import { selectParams } from 'src/app/core/router/store/router.selectors';
import * as fromLocations from '../reducer/locations.reducer';

export const locationsFeatureSelector = createFeatureSelector<fromLocations.LocationsState>(
  fromLocations.locationsFeatureKey
);

const { selectEntities, selectAll } = fromLocations.locationsAdapter.getSelectors();

export const selectLocationsEntities = createSelector(locationsFeatureSelector, selectEntities);

export const selectLocations = createSelector(locationsFeatureSelector, selectAll);

export const selectError = createSelector(locationsFeatureSelector, state => state.baseState.error);

export const selectIsLoading = createSelector(locationsFeatureSelector, state => state.baseState.isLoading);

export const selectPageNumber = createSelector(locationsFeatureSelector, state => state.baseState.pageNumber);

export const selectIsAtEndOfData = createSelector(locationsFeatureSelector, state => state.baseState.isAtEndOfData);

export const selectSortDirection = createSelector(locationsFeatureSelector, state => state.baseState.sortDirection);

export const selectSortBy = createSelector(locationsFeatureSelector, state => state.baseState.sortBy);

export const selectPageSize = createSelector(locationsFeatureSelector, state => state.baseState.pageSize);

export const selectTotalRecordCount = createSelector(
  locationsFeatureSelector,
  state => state.baseState.totalRecordCount
);

export const selectSearchTerm = createSelector(locationsFeatureSelector, state => state.baseState.searchTerm);

export const selectIsActive = createSelector(locationsFeatureSelector, state => state.baseState.isActive);

export const selectLocation = createSelector(
  selectLocationsEntities,
  selectParams,
  (locations, { locationId }) => locations[locationId]
);
