import { Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-user-menu',
  standalone: true,
  imports: [MatIconModule, MatMenuModule],
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss']
})
export class UserMenuComponent {
  $themeName: Observable<string>;
  $userInitials: Observable<string | null>;

  constructor(private store: Store) {}
}
