import { createReducer, on } from '@ngrx/store';
import { UsState } from 'src/app/shared/models/state';
import * as actions from '../actions/shared.actions';

export const sharedFeatureKey = 'shared';

export interface SharedState {
  usStates: UsState[] | null;
}

export const initialState: SharedState = {
  usStates: null
};

export const sharedReducer = createReducer<SharedState>(
  initialState,

  on(actions.getUsStates, state => {
    return {
      ...state,
      usStates: null
    };
  }),

  on(actions.getUsStatesSuccess, (state, { usStates }) => {
    return {
      ...state,
      usStates
    };
  }),

  on(actions.getUsStatesFailure, state => {
    return {
      ...state,
      usStates: null
    };
  })
);
