<ng-container *ngrxLet="{ appointmentTypeCategories: appointmentTypeCategories$, error: error$ } as vm">
  <app-progress-spinner
    *ngIf="vm.appointmentTypeCategories === null; else addAppointmentTypeForm"></app-progress-spinner>

  <ng-template #addAppointmentTypeForm>
    <div class="page-title">
      <h3 class="text-center flex-2">Add New Appointment Type</h3>
    </div>

    <app-upsert-appointment-type-form
      [saveError]="vm.error"
      [categories]="vm.appointmentTypeCategories"
      (closeAddAppointmentEvent)="closeAppointmentType()"
      (addAppointmentTypeEvent)="addAppointmentType($event)"></app-upsert-appointment-type-form>
  </ng-template>
</ng-container>
