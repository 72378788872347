import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { APP_INITIALIZER, ApplicationConfig, importProvidersFrom, isDevMode } from '@angular/core';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideRouter } from '@angular/router';
import { provideEffects } from '@ngrx/effects';
import { provideRouterStore, routerReducer } from '@ngrx/router-store';
import { provideState, provideStore } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { routes } from './app.routes';
import { AppointmentTypeEffects } from './appointment-types/store/effects/appointment-types.effects';
import { appointmentTypeReducer } from './appointment-types/store/reducer/appointment-types.reducer';
import { CustomRouterStateSerializer } from './core/router/router-state-serializer';
import { ConfigService } from './core/services/config/config.service';
import { ErrorInterceptor } from './core/services/http-interceptor/http-interceptor.service';
import { AppEffects } from './core/store/effects/app.effects';
import { appReducer } from './core/store/reducer/app.reducer';
import { LocationsEffects } from './locations/store/effects/locations.effects';
import { locationsReducer } from './locations/store/reducer/locations.reducer';
import { ProvidersEffects } from './providers/store/effects/providers.effects';
import { providersReducer } from './providers/store/reducer/providers.reducer';
import { ResourcesEffects } from './resources/store/effects/resources.effects';
import { resourcesReducer } from './resources/store/reducer/resources.reducer';
import { SharedEffects } from './shared/store/effects/shared.effects';
import { sharedReducer } from './shared/store/reducer/shared.reducer';
import { userReducer } from './user/store/reducer/user.reducer';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    importProvidersFrom(LoggerModule.forRoot({ level: NgxLoggerLevel.DEBUG })),
    provideHttpClient(withInterceptorsFromDi()),
    provideStore({ router: routerReducer }),
    provideRouterStore({ serializer: CustomRouterStateSerializer }),
    provideState({ name: 'app', reducer: appReducer }),
    provideState({ name: 'appointment-types', reducer: appointmentTypeReducer }),
    provideState({ name: 'locations', reducer: locationsReducer }),
    provideState({ name: 'resources', reducer: resourcesReducer }),
    provideState({ name: 'shared', reducer: sharedReducer }),
    provideState({ name: 'user', reducer: userReducer }),
    provideState({ name: 'providers', reducer: providersReducer }),
    provideEffects([
      AppEffects,
      AppointmentTypeEffects,
      LocationsEffects,
      ResourcesEffects,
      SharedEffects,
      ProvidersEffects
    ]),
    provideStoreDevtools({
      maxAge: 25, // Retains last 25 states
      logOnly: !isDevMode(), // Restrict extension to log-only mode
      autoPause: true, // Pauses recording actions and state changes when the extension window is not open
      trace: false, //  If set to true, will include stack trace for every dispatched action, so you can see it in trace tab jumping directly to that part of code
      traceLimit: 75, // maximum stack trace frames to be stored (in case trace option was provided as true)
      connectInZone: true // If set to true, the connection is established within the Angular zone
    }),
    provideAnimationsAsync(),
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [ConfigService, HttpClient],
      useFactory: (configService: ConfigService) => () => configService.loadConfig()
    }
  ]
};
