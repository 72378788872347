import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { NGXLogger } from 'ngx-logger';
import { catchError, map, mergeMap, of, startWith, switchMap, tap } from 'rxjs';
import { selectParams } from 'src/app/core/router/store/router.selectors';
import { ResourceAvailabilitiesService } from '../../services/resource-availabilities/resource-availabilities.service';
import { ResourceTypesService } from '../../services/resource-types/resource-types.service';
import { ResourcesService } from '../../services/resources/resources.service';
import * as resourcesActions from '../actions/resources.actions';
import * as resourcesSelector from '../selectors/resources.selectors';

@Injectable()
export class ResourcesEffects {
  constructor(
    private actions$: Actions,
    private resourcesService: ResourcesService,
    private resourceTypesService: ResourceTypesService,
    private resourceAvailabilitiesService: ResourceAvailabilitiesService,
    private store: Store,
    private logger: NGXLogger,
    private router: Router
  ) {}

  getResources$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        resourcesActions.getResources,
        resourcesActions.setPageNumber,
        resourcesActions.setPageSize,
        resourcesActions.getResourcesSearch,
        resourcesActions.setSortBy,
        resourcesActions.setIsActive
      ),
      concatLatestFrom(() => [
        this.store.select(resourcesSelector.selectPageNumber),
        this.store.select(resourcesSelector.selectSortDirection),
        this.store.select(resourcesSelector.selectSortBy),
        this.store.select(resourcesSelector.selectPageSize),
        this.store.select(resourcesSelector.selectSearchTerm),
        this.store.select(resourcesSelector.selectIsActive)
      ]),
      mergeMap(([, pageNumber, sortDirection, sortBy, pageSize, searchTerm, isActive]) => {
        return this.resourcesService
          .postSearchResources(pageNumber, sortDirection, sortBy, pageSize, searchTerm, isActive)
          .pipe(
            map(response => {
              let isAtEndOfData = false;
              const totalRecordCount = response.totalRecordCount;
              let currentPage = pageNumber;
              let resources = response.result;

              if (resources == null || resources.length == 0) {
                resources = [];
                currentPage--;
                isAtEndOfData = true;
              }

              return resourcesActions.getResourcesSuccess({
                resources,
                pageNumber: currentPage <= 0 ? 1 : currentPage,
                isAtEndOfData,
                totalRecordCount
              });
            }),
            catchError(err => {
              this.logger.error(err);
              return of(resourcesActions.getResourcesFailure({ error: err }));
            })
          );
      })
    );
  });

  navigateToAddResource$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(resourcesActions.navigateToAddResource),
        tap(() => {
          this.router.navigate(['resources/add-resource']);
        })
      ),
    { dispatch: false }
  );

  navigateToResourcesGrid$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          resourcesActions.navigateToResourcesGrid,
          resourcesActions.addResourceSuccess,
          resourcesActions.updateResourceSuccess
        ),
        tap(() => {
          this.router.navigate(['resources']);
        })
      ),
    { dispatch: false }
  );

  addResource$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(resourcesActions.addResource),
      map(action => action.resource),
      switchMap(resourceProfile => {
        return this.resourcesService
          .addResource(resourceProfile)
          .pipe(map(resource => ({ resourceProfile, resource })));
      }),
      switchMap(payload => {
        if (payload.resourceProfile.resourceAvailabilities.isDirty) {
          return this.resourceAvailabilitiesService
            .postResourceAvailabilities(
              payload.resource.resourceId,
              payload.resourceProfile.resourceAvailabilities.dayOfWeekAvailabilities
            )
            .pipe(map(payload => payload));
        } else {
          return of(payload);
        }
      }),
      map(() => {
        return resourcesActions.addResourceSuccess();
      }),
      catchError((err, source) => {
        return source.pipe(startWith(resourcesActions.addResourceFailure({ error: err.error?.errors })));
      })
    );
  });

  getResourceTypes$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(resourcesActions.getResourceTypes),
      mergeMap(() => {
        return this.resourceTypesService.getResourceTypes().pipe(
          map(resourceTypes => {
            return resourcesActions.getResourceTypesSuccess({ resourceTypes });
          }),
          catchError(error => {
            this.logger.error(error);
            return of(resourcesActions.getResourceTypesFailure({ error }));
          })
        );
      })
    );
  });

  navigateToUpdateResource$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(resourcesActions.navigateToUpdateResource),
        tap(action => {
          this.router.navigate(['resources/update-resource', action.resourceId]);
        })
      ),
    { dispatch: false }
  );

  getResourceById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(resourcesActions.getResourceById),
      concatLatestFrom(() => this.store.select(selectParams)),
      mergeMap(([, { resourceId }]) => {
        return this.resourcesService.getResourceById(resourceId).pipe(
          map(resource => {
            return resourcesActions.getResourceByIdSuccess({ resource });
          }),
          catchError(error => {
            this.logger.error(error);
            return of(resourcesActions.getResourceByIdFailure({ error }));
          })
        );
      })
    );
  });

  updateResource$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(resourcesActions.updateResource),
      mergeMap(action => {
        return this.resourcesService.updateResource(action.resourceToUpdate).pipe(
          map(() => {
            return resourcesActions.updateResourceSuccess();
          }),
          catchError(error => {
            this.logger.error(error);
            return of(resourcesActions.updateResourceFailure({ error }));
          })
        );
      })
    );
  });
}
