import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UtilityService } from 'src/app/core/services/utility/utility.service';
import { Availability } from 'src/app/shared/models/availability';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ResourceAvailabilitiesService {
  constructor(private utilityService: UtilityService) {}

  resourceAvailabilitiesEndpoint = environment.scheduleApiUrl + '/ResourceAvailabilities';

  postResourceAvailabilities(resourceId: number, resourceAvailabilities: Availability[]): Observable<Availability[]> {
    const body = {
      resourceId,
      dayOfWeekAvailabilities: resourceAvailabilities,
      modifiedByUserId: 1
    };

    return this.utilityService.postRequest<Availability[]>(this.resourceAvailabilitiesEndpoint, body);
  }
}
