<ng-container *ngIf="resourceTypes !== null && locations !== null && modifiedByUserId !== null">
  <form [formGroup]="form" (submit)="save()" class="ml-30 mt-30">
    @if (saveError !== null && isSaving === false) {
      <div id="saveError" class="ml-20 mr-20 mb-20 mt-15 flex justify-center">
        <p>Error! Please try again later.</p>
      </div>
    }

    @if (deactivatedLocation) {
      <div class="deactivated-location-error">
        <mat-icon>warning</mat-icon>
        <div>Location has been deactivated. Please choose a different location.</div>
      </div>
    }

    <div class="flex gap-40">
      <div class="flex flex-col gap-20 flex-1">
        <mat-form-field id="name" appearance="outline" subscriptSizing="dynamic" floatLabel="always">
          <mat-label>Resource Name</mat-label>
          <input matInput #resourceName id="resourceName" formControlName="nameControl" data-test="iptName" />
          @if (form.controls['nameControl'].hasError('required')) {
            <mat-error id="nameRequired">Name required</mat-error>
          }
          @if (form.controls['nameControl'].hasError('maxlength')) {
            <mat-error id="nameMaxLengthError">Must not exceed {{ maxLengthName }} characters</mat-error>
          }
        </mat-form-field>

        <mat-form-field id="color" appearance="outline" subscriptSizing="dynamic" floatLabel="always">
          <mat-label>Color</mat-label>
          <mat-select
            hideSingleSelectionIndicator
            formControlName="colorControl"
            class="color-select"
            data-test="selHexColorCode">
            <mat-select-trigger>
              <div class="flex justify-between">
                {{ form.controls['colorControl'].value?.name }}
                <div
                  class="color-pill"
                  [style]="'background-color: ' + form.controls['colorControl'].value?.hexCode"></div>
              </div>
            </mat-select-trigger>
            @for (color of colors; track color) {
              <mat-option [value]="color">
                <div class="flex justify-start w-100p">
                  <div class="w-20x h-20x mr-8" [style]="'background-color: ' + color.hexCode"></div>
                  {{ color.name }}
                </div>
              </mat-option>
            }
          </mat-select>
          @if (form.controls['colorControl'].hasError('required')) {
            <mat-error id="colorRequired">Color required</mat-error>
          }
        </mat-form-field>

        <mat-form-field id="type" appearance="outline" subscriptSizing="dynamic" floatLabel="always">
          <mat-label>Resource Type</mat-label>
          <mat-select formControlName="typeControl" class="type-select" data-test="selResourceType">
            @for (type of resourceTypes; track type) {
              <mat-option [value]="type.resourceTypeLookupId">
                {{ type.description }}
              </mat-option>
            }
          </mat-select>
          @if (form.controls['typeControl'].hasError('required')) {
            <mat-error id="typeRequired">Resource type required</mat-error>
          }
        </mat-form-field>

        <mat-form-field id="location" appearance="outline" subscriptSizing="dynamic" floatLabel="always">
          <mat-label>Location</mat-label>
          <mat-select
            formControlName="locationControl"
            class="type-select"
            data-test="selLocation"
            (selectionChange)="deactivatedLocation = false">
            @for (location of locations; track location) {
              <mat-option [value]="location.locationId">
                {{ location.name }}
              </mat-option>
            }
          </mat-select>
          @if (form.controls['locationControl'].hasError('required')) {
            <mat-error id="locationRequired">Location required</mat-error>
          }
        </mat-form-field>

        <mat-form-field id="date" appearance="outline" subscriptSizing="dynamic" floatLabel="always">
          <mat-label>Inactive Date</mat-label>
          <input
            matInput
            [matDatepicker]="picker"
            #inactiveDate
            id="inactiveDate"
            formControlName="inactiveDateControl"
            data-test="dteDeactivateOn"
            [min]="today" />
          <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
          @if (form.controls['inactiveDateControl'].invalid) {
            <mat-error id="dateInvalid">Date cannot be in the past</mat-error>
          }
        </mat-form-field>
      </div>

      <div class="flex-2"></div>
    </div>

    <div class="btn-container flex justify-end align-center mb-20">
      <div>
        <button
          class="btn warn-button"
          id="btnCancel"
          color="warn"
          mat-stroked-button
          type="button"
          data-test="btnCancel"
          (click)="closeAddResourceEvent.emit()">
          Cancel
        </button>
      </div>

      <div class="ml-10">
        <button
          class="btn primary-solid-button"
          id="btnSubmit"
          color="primary"
          mat-raised-button
          type="submit"
          data-test="btnSave"
          [disabled]="isSaving">
          Save
        </button>
      </div>
    </div>
  </form>
</ng-container>

<app-progress-spinner-overlay
  [displayProgressSpinner]="isSaving"
  data-test="spinnerAddResource"></app-progress-spinner-overlay>
