import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom, map } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { UtilityService } from '../utility/utility.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private utilityService: UtilityService) {}

  async getAuthorization(): Promise<boolean> {
    const url = environment.loginApiUrl + '/Authorize';
    return await lastValueFrom(this.utilityService.getRequest(url).pipe(map(() => true))).catch(() => false);
  }

  redirectToLogin(): void {
    const params = new HttpParams().set('redirectUrl', window.location.href);
    const loginUrl = `${environment.loginUrl}?${params.toString()}`;
    document.location.href = loginUrl;
  }
}
