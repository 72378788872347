import { DataSort } from '../../core/models/data-sort';

export const appointmentTypeSort: DataSort[] = [
  {
    id: 1,
    value: 'name',
    display: 'Appointment Type',
    isDefault: true
  },
  {
    id: 2,
    value: 'appointmentTypeCategory',
    display: 'Category',
    isDefault: false
  }
];
