import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { providerSort } from '../../data/provider-sort';
import { ProviderResource } from '../../models/provider';
import * as providersActions from '../actions/providers.actions';
import * as baseState from 'src/app/core/models/base-state';

export interface ProvidersState extends EntityState<ProviderResource> {
  baseState: baseState.BaseState;
}

export const providersAdapter = createEntityAdapter<ProviderResource>({
  selectId: provider => provider.providerId
});

export const initialState = providersAdapter.getInitialState({
  baseState: new baseState.BaseState(providerSort[0])
});

export const providersFeatureKey = 'providers';

export const providersReducer = createReducer(
  initialState,

  on(providersActions.getProviders, state => {
    return {
      ...state,
      baseState: baseState.setGet(state.baseState, false)
    };
  }),

  on(providersActions.getProvidersSuccess, (state, { providers }) => providersAdapter.addMany(providers, state)),

  on(providersActions.getProvidersSuccess, (state, { pageNumber, isAtEndOfData, totalRecordCount }) => {
    return {
      ...state,
      baseState: baseState.setGetSuccess(state.baseState, pageNumber, isAtEndOfData, totalRecordCount)
    };
  }),

  on(providersActions.getProvidersFailure, (state, { error }) => {
    return {
      ...state,
      baseState: baseState.setError(state.baseState, error)
    };
  }),

  on(
    providersActions.getProviders,
    providersActions.setPageSize,
    providersActions.setPageNumber,
    providersActions.getProvidersSearch,
    providersActions.setSortBy,
    providersActions.setSortDirection,
    providersActions.setIsActive,
    state => providersAdapter.removeAll(state)
  ),

  on(providersActions.setPageNumber, (state, { pageNumber }) => {
    return {
      ...state,
      baseState: baseState.setPageNumber(state.baseState, pageNumber)
    };
  }),

  on(providersActions.setPageSize, (state, { pageSize }) => {
    return {
      ...state,
      baseState: baseState.setPageSize(state.baseState, pageSize)
    };
  }),

  on(providersActions.getProvidersSearch, (state, { searchTerm }) => {
    return {
      ...state,
      baseState: baseState.setSearch(state.baseState, searchTerm)
    };
  }),

  on(providersActions.setSortDirection, (state, { sortDirection }) => {
    return {
      ...state,
      baseState: baseState.setSortDirection(state.baseState, sortDirection)
    };
  }),

  on(providersActions.setSortBy, (state, { sortBy }) => {
    return {
      ...state,
      baseState: baseState.setSortBy(state.baseState, sortBy)
    };
  }),

  on(providersActions.setIsActive, (state, { isActive }) => {
    return {
      ...state,
      baseState: baseState.setIsActive(state.baseState, isActive)
    };
  }),

  on(providersActions.resetFilters, state => {
    return {
      ...state,
      baseState: baseState.resetFilters(state.baseState, providerSort[0])
    };
  })
);
