import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatNativeDateModule, provideNativeDateAdapter } from '@angular/material/core';
import { MatDatepicker, MatDatepickerInput, MatDatepickerToggle } from '@angular/material/datepicker';
import { MatError, MatFormField } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { DxDateBoxModule, DxValidatorModule } from 'devextreme-angular';
import moment from 'moment';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import { MultiselectAutocompleteComponent } from 'src/app/shared/component/multiselect-autocomplete/multiselect-autocomplete.component';
import { ProgressSpinnerOverlayComponent } from 'src/app/shared/component/progress-spinner-overlay/progress-spinner-overlay.component';
import { ProgressSpinnerComponent } from 'src/app/shared/component/progress-spinner/progress-spinner.component';
import { MultiSelectAutoCompleteItem } from 'src/app/shared/models/multiselect-autocomplete-item';
import { UsState } from 'src/app/shared/models/state';
import { locationLengthValidators, locationSettings } from '../../data/location-form';
import { createLocationForm, createLocationToSave } from '../../functions/location-form.functions';
import { ApptLocation } from '../../models/location';

@Component({
  selector: 'app-edit-location-form',
  standalone: true,
  imports: [
    CommonModule,
    DxDateBoxModule,
    DxValidatorModule,
    FormsModule,
    MatButtonModule,
    MatDatepicker,
    MatDatepickerInput,
    MatDatepickerToggle,
    MatError,
    MatFormField,
    MatInputModule,
    NgxMaskDirective,
    MatNativeDateModule,
    MatSelectModule,
    MultiselectAutocompleteComponent,
    ProgressSpinnerComponent,
    ProgressSpinnerOverlayComponent,
    ReactiveFormsModule
  ],
  providers: [provideNgxMask(), provideNativeDateAdapter()],
  templateUrl: './edit-location-form.component.html',
  styleUrl: './edit-location-form.component.scss'
})
export class EditLocationFormComponent {
  fieldValidators = locationLengthValidators;
  locationSettings = locationSettings;
  isSaving: boolean = false;
  error: string[] | null = null;

  form = createLocationForm(false);
  location: ApptLocation | null = null;
  apptTypeMultiSelectItems: MultiSelectAutoCompleteItem[];
  selectedAppointmentTypeQuickPicks: MultiSelectAutoCompleteItem[] | null = null;

  @Input() usStates: UsState[] | null;
  @Input() appointmentTypeQuickPicks: MultiSelectAutoCompleteItem[] | null = null;
  @Input() modifiedByUserId: number;
  @Input() set selectedLocation(value: ApptLocation | null) {
    if (
      value !== null &&
      value !== undefined &&
      value.locationAppointmentTypes !== undefined &&
      value.locationAvailabilities !== undefined
    ) {
      this.location = value;

      this.form.controls['locationProfile'].controls['locationName'].patchValue(this.location.name);

      const chosenColor = this.locationSettings.colors.find(el => el.hexCode === this.location.hexColorCode);
      this.form.controls['locationProfile'].controls['color'].patchValue(chosenColor);

      this.form.controls['locationProfile'].controls['locationShortName'].patchValue(this.location.shortName);
      this.form.controls['locationProfile'].controls['addressLine1'].patchValue(this.location.address1);
      this.form.controls['locationProfile'].controls['addressLine2'].patchValue(this.location.address2);
      this.form.controls['locationProfile'].controls['city'].patchValue(this.location.city);
      this.form.controls['locationProfile'].controls['state'].patchValue(this.location.stateAbbreviation);
      this.form.controls['locationProfile'].controls['zip'].patchValue(this.location.postalCode);
      this.form.controls['locationProfile'].controls['phone'].patchValue(this.location.phoneNumber);
      this.form.controls['locationProfile'].controls['fax'].patchValue(this.location.faxNumber);
      this.form.controls['locationProfile'].controls['taxId'].patchValue(this.location.taxpayerIdentificationNumber);

      if (this.location.deactivateOn !== null && this.location.deactivateOn !== undefined) {
        const strDate = this.location.deactivateOn + ' 12:00:00Z';
        this.form.controls['locationProfile'].controls['activeDates'].get('endDate')?.patchValue(new Date(strDate));
      }

      if (this.location.activateOn !== null && this.location.activateOn !== undefined) {
        const strDate = this.location.activateOn + ' 12:00:00Z';
        this.form.controls['locationProfile'].controls['activeDates'].get('startDate')?.patchValue(new Date(strDate));
      }

      this.form.controls['locationProfile'].controls['tips'].patchValue(this.location.notes);

      if (
        this.location.locationAppointmentTypes.appointmentTypeIds !== null &&
        this.location.locationAppointmentTypes.appointmentTypeIds.length > 0
      ) {
        this.selectedAppointmentTypeQuickPicks = this.appointmentTypeQuickPicks.filter(ft =>
          this.location.locationAppointmentTypes.appointmentTypeIds.find(fd => ft.id === fd)
        );
      }

      this.locationSettings.daysOfWeek.forEach(dow => {
        if (
          this.location.locationAvailabilities.dayOfWeekAvailabilities !== null &&
          this.location.locationAvailabilities.dayOfWeekAvailabilities.length > 0 &&
          this.location.locationAvailabilities.dayOfWeekAvailabilities.filter(
            f => f.dayOfWeekTypeId === dow.dayOfWeekId
          ).length > 0
        ) {
          const dayAvailability = this.location.locationAvailabilities.dayOfWeekAvailabilities.find(
            f => f.dayOfWeekTypeId === dow.dayOfWeekId
          );
          this.form.controls['availability'].controls[dow.name.toLocaleLowerCase()]
            .get('startTime')
            ?.patchValue(moment(dayAvailability.startTimeLocal, 'h:mm a'));
          this.form.controls['availability'].controls[dow.name.toLocaleLowerCase()]
            .get('endTime')
            ?.patchValue(moment(dayAvailability.endTimeLocal, 'h:mm a'));
        }
      });
    }
  }
  @Input() set saveError(value: any) {
    if (value !== null && value !== undefined) {
      this.isSaving = false;

      if (value.filter((f: string) => f.toLowerCase().includes('location name')).length > 0) {
        this.form.controls['locationProfile'].controls['locationName'].setErrors({ duplicate: true });
      } else if (value.filter((f: string) => f.toLowerCase().includes('location short name')).length > 0) {
        this.form.controls['locationProfile'].controls['locationShortName'].setErrors({ duplicate: true });
      } else {
        this.error = value;
      }
    }
  }

  @Output() closeLocationEvent = new EventEmitter();
  @Output() updateLocationEvent = new EventEmitter<ApptLocation>();

  onMultiSelectChange = (event: any) => {
    this.form.controls['appointmentTypeQuickPicks'].patchValue(event.data);

    if (
      this.location.locationAppointmentTypes.appointmentTypeIds.length ===
      this.form.controls['appointmentTypeQuickPicks'].value.length
    ) {
      const missing = this.location.locationAppointmentTypes.appointmentTypeIds.filter(
        item => this.form.controls['appointmentTypeQuickPicks'].value.map(m => m.id).indexOf(item) < 0
      );
      if (missing.length > 0) {
        this.form.controls['appointmentTypeQuickPicks'].markAsDirty();
      } else {
        this.form.controls['appointmentTypeQuickPicks'].markAsPristine();
      }
    } else {
      this.form.controls['appointmentTypeQuickPicks'].markAsDirty();
    }
  };

  isStartTimeValid(e: string) {
    return this.form.controls['availability'].get(e.toLocaleLowerCase())?.get('startTime')?.errors !== null;
  }

  isEndTimeValid(e: string) {
    return this.form.controls['availability'].get(e.toLocaleLowerCase())?.get('endTime')?.errors !== null;
  }

  save() {
    this.isSaving = true;

    if (this.form.valid) {
      const location = createLocationToSave(
        this.form,
        this.location.locationId,
        this.location.version,
        this.modifiedByUserId
      );

      this.updateLocationEvent.emit(location);
    } else {
      this.isSaving = false;
    }
  }
}
