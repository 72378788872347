import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatNativeDateModule, MatOption, provideNativeDateAdapter } from '@angular/material/core';
import { MatDatepicker, MatDatepickerInput, MatDatepickerToggle } from '@angular/material/datepicker';
import { MatError, MatFormField, MatLabel } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput, MatInputModule } from '@angular/material/input';
import { MatSelect, MatSelectTrigger } from '@angular/material/select';
import { Color } from 'src/app/core/models/colors';
import { locationColors } from 'src/app/locations/data/location-colors';
import { ApptLocation } from 'src/app/locations/models/location';
import { ProgressSpinnerOverlayComponent } from 'src/app/shared/component/progress-spinner-overlay/progress-spinner-overlay.component';
import { Resource } from '../../models/resource';
import { ResourceType } from '../../models/resource-type';

@Component({
  selector: 'app-upsert-resource-form',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ProgressSpinnerOverlayComponent,
    MatError,
    MatLabel,
    MatFormField,
    MatDatepicker,
    MatDatepickerInput,
    MatDatepickerToggle,
    MatOption,
    MatSelectTrigger,
    MatNativeDateModule,
    FormsModule,
    MatInputModule,
    MatInput,
    MatButton,
    MatSelect,
    MatIcon
  ],
  providers: [provideNativeDateAdapter()],
  templateUrl: './upsert-resource-form.component.html',
  styleUrl: './upsert-resource-form.component.scss'
})
export class UpsertResourceFormComponent {
  maxLengthName = 50;

  form = new FormGroup({
    nameControl: new FormControl<string | null>(null, [Validators.required, Validators.maxLength(this.maxLengthName)]),
    colorControl: new FormControl<Color | null>(null, Validators.required),
    typeControl: new FormControl<number | null>(null, Validators.required),
    locationControl: new FormControl<number | null>(null, Validators.required),
    inactiveDateControl: new FormControl<Date | null>(null)
  });

  today = new Date();
  colors = locationColors;
  deactivatedLocation = false;
  resource: Resource;
  isSaving: boolean = false;

  @Input() set resourceToUpdate(value: Resource | null) {
    if (value !== null) {
      const location = this.locations.find(el => el.locationId === value.locationId);
      this.deactivatedLocation = location === undefined && this.locations.length != 0;
      const chosenColor = this.colors.find(el => el.hexCode === value.hexColorCode);

      this.form.controls['nameControl'].patchValue(value.name);
      this.form.controls['colorControl'].patchValue(chosenColor);
      this.form.controls['typeControl'].patchValue(value.resourceTypeLookupId);
      this.form.controls['locationControl'].patchValue(value.locationId);
      if (value.deactivateOn !== null && value.deactivateOn !== undefined) {
        const strDate = value.deactivateOn + ' 12:00:00Z';
        this.form.controls['inactiveDateControl'].patchValue(new Date(strDate));
      }
      this.resource = value;
    }
  }

  @Input() saveError: string[] | null = null;
  @Input() resourceTypes: ResourceType[] | null;
  @Input() locations: ApptLocation[] | null;
  @Input() modifiedByUserId: number;
  @Output() closeAddResourceEvent = new EventEmitter();
  @Output() saveResourceEvent = new EventEmitter<Resource>();

  save() {
    this.isSaving = true;

    if (this.form.valid) {
      const inactiveDate = new Date(this.form.controls['inactiveDateControl'].value);
      const inactiveDateStr = this.form.controls['inactiveDateControl'].value
        ? inactiveDate.toJSON().substring(0, 10)
        : null;

      const resource: Resource = {
        name: this.form.controls['nameControl'].value,
        hexColorCode: this.form.controls['colorControl'].value.hexCode,
        resourceTypeLookupId: this.form.controls['typeControl'].value,
        locationId: this.form.controls['locationControl'].value,
        deactivateOn: inactiveDateStr,
        resourceId: this.resource?.resourceId,
        version: this.resource?.version,
        modifiedByUserId: this.modifiedByUserId
      };

      this.saveResourceEvent.emit(resource);
    } else {
      this.isSaving = false;
    }
  }
}
