import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { User } from '../../models/user';
import { createReducer } from '@ngrx/store';

export interface UserState extends EntityState<User> {
  userId: number;
}

export const userAdapter = createEntityAdapter<User>({
  selectId: user => user.userId
});

export const initialState = userAdapter.getInitialState({
  userId: 1
});

export const userFeatureKey = 'user';

export const userReducer = createReducer(initialState);
