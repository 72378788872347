<div class="flex justify-center">
  <div class="table-pager-container mb-20">
    <div class="resources-table-container">
      <table
        mat-table
        [dataSource]="dataSource"
        matSort
        matSortDisableClear
        (matSortChange)="onSort($event)"
        [matSortDirection]="matSortDirection"
        [matSortActive]="matSortActive"
        data-test="grdResources">
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="fw-600">Resource</th>
          <td mat-cell *matCellDef="let data" tabindex="0" [ngClass]="{ italic: !data.isActive }">
            <div class="flex">
              {{ data.name }}
              @if (getIsFutureDate(data.activateOn)) {
                <div class="italic ml-5">(active on {{ formatDate(data.activateOn) }})</div>
              }
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="resourceType">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="fw-600">Type</th>
          <td mat-cell *matCellDef="let data" tabindex="0" [ngClass]="{ italic: !data.isActive }">
            {{ data.resourceType }}
          </td>
        </ng-container>
        <ng-container matColumnDef="locationName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="fw-600">Location</th>
          <td mat-cell *matCellDef="let data" tabindex="0" [ngClass]="{ italic: !data.isActive }">
            {{ data.locationName }}
          </td>
        </ng-container>
        <ng-container matColumnDef="color">
          <th mat-header-cell *matHeaderCellDef class="fw-600">Color</th>
          <td mat-cell *matCellDef="let data" tabindex="0">
            <div class="w-100p flex justify-center">
              <div class="color-block" [style]="'background-color: ' + data.hexColorCode"></div>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef class="fw-600">Status</th>
          <td mat-cell *matCellDef="let data" tabindex="0">
            <div class="w-100p flex justify-center">
              @if (data.isActive) {
                <div>Active</div>
              } @else {
                <div class="italic">Inactive</div>
              }
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="edit">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let data" tabindex="0">
            <div
              class="fw-600 edit-btn text-right cursor"
              tabindex="0"
              (click)="editResourceEvent.emit(data.resourceId)"
              (keypress)="editResourceEvent.emit(data.resourceId)">
              Edit
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

        <tr class="mat-row" *matNoDataRow>
          <td
            *ngIf="!isLoading && error === null"
            class="mat-cell pl-20 pt-10"
            [attr.colspan]="displayedColumns.length">
            <p class="flex justify-center align-center mt-10 mb-10">No Resources Found</p>
          </td>
          <td
            *ngIf="!isLoading && error !== null"
            class="mat-cell pl-20 pt-10"
            [attr.colspan]="displayedColumns.length">
            <p class="flex justify-center align-center mt-10 mb-10">{{ error }}</p>
          </td>
          <td *ngIf="isLoading" class="mat-cell" [attr.colspan]="displayedColumns.length">
            <div class="flex justify-center align-center mt-10 mb-10">
              <app-progress-spinner [diameter]="100"></app-progress-spinner>
            </div>
          </td>
        </tr>
      </table>
    </div>
    <mat-paginator
      [disabled]="isLoading"
      [pageSizeOptions]="pageSizeOptions"
      [pageSize]="currentPageSize"
      [length]="paginatorLength"
      [pageIndex]="currentPage - 1"
      showFirstLastButtons
      (page)="onPage($event)"
      class="pt-10 pb-10 paginator"
      aria-label="Select page of data"
      data-test="pgnResource">
    </mat-paginator>
  </div>
</div>
