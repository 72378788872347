import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UtilityService } from 'src/app/core/services/utility/utility.service';
import { LocationAppointmentType } from 'src/app/locations/models/location-appointment-type';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LocationAppointmentTypesService {
  constructor(private utilityService: UtilityService) {}

  endpoint = environment.scheduleApiUrl + '/LocationAppointmentTypes';

  postLocationAppointmentTypes(
    locationId: number,
    appointmentTypeIds: number[]
  ): Observable<LocationAppointmentType[]> {
    const body = {
      locationId,
      appointmentTypeIds,
      modifiedByUserId: 1
    };

    return this.utilityService.postRequest<LocationAppointmentType[]>(this.endpoint, body);
  }

  getLocationAppointmentTypesByLocationId(locationId: number): Observable<LocationAppointmentType[]> {
    const url = this.endpoint + '?locationId=' + locationId;
    return this.utilityService.getRequest<LocationAppointmentType[]>(url);
  }
}
